import React, { Component } from "react";
import "./Search.scss";
import Strings from "../../config/Strings";
import {TigerSpinner} from "../../global/tiger-spinner/TigerSpinner";
import SearchResultItem from "./SearchResultItem";
import SeriesResultItem from "./SeriesResultItem";
import {GetFilteredProducts, GetStartpage} from "./SearchService";
import {withToast} from "../../util/ToastService";
import {Form, FormSelect} from "react-bootstrap";
import {PaginationHelp} from "../../global/pagination-help/PaginationHelp";
import {FaSearch} from "react-icons/all";
import {Helmet} from "react-helmet";
import {GetProfileRecommendations, GetProfilesOfAccount} from "../profiles/ProfileService";

class SearchResult extends Component {
    constructor(props) {
        super(props);
        const queryParams = new URLSearchParams(window.location.search);
        this.state = {
            profileId: null,
            loading: false,
            filter: props.state != null ? props.state.filter : {
                page: 0,
                totalPages: 0,
                query: queryParams.get("query") ? queryParams.get("query") : "",
                states: ['ACTIVE'],
                language: queryParams.get("language") ? queryParams.get("language") : null,
                ageMin: queryParams.get("ageMin") ? queryParams.get("ageMin") : "",
                ageMax: queryParams.get("ageMax") ? queryParams.get("ageMax") : "",
                classification: ['RADIOPLAY', 'AUDIOBOOK', 'MUSIC'],
                productType: ['AUDIOBOOK'],
                visibilityCountry: "DE",
                productGroupId: [],
                searchRange: ['ALL'],
                per_page: 20,
                sortBy: "",
                sortDirection: "",
            },
            products: [],
            series: [],
            recommendations: [],
            top10: [],
            top10music: [],
            randomSeries: [],
        };
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.accountId !== this.props.accountId) {
            this.setState(prevState => ({
                ...prevState,
                accountId: this.props.accountId,
            }), () => {
                this.getStartPage();
            });
        }
    }

    async componentDidMount() {
        document.title = "Suche :: tiger.media";
        if (this.state.filter.query && this.state.filter.query.length > 0) {
            await this.searchProducts(0);
        } else {
            await this.getStartPage();
        }
    }

    async getStartPage() {
        this.setState({
            loading: true,
        });

        const response = await GetStartpage(this.state.filter.language);
        let top10 = [];
        let top10music = [];
        let randomSeries = [];
        if (response.error === true) {
            this.setState(prevState => ({...prevState, error: response}));
        } else {
            top10 = response.result._embedded.top10;
            top10music = response.result._embedded.top10music;
            randomSeries = response.result._embedded.series;
        }

        this.setState(prevState => ({
            ...prevState,
            top10: top10,
            top10music: top10music,
            randomSeries: randomSeries,
        }), () => {
            this.loadRecommendations(this.props.accountId);
        });

        this.setState({
            loading: false,
        }, () => this.scrollTo());

    }


    async searchProducts(page) {
        this.setState({
            loading: true,
        });

        let filter = this.state.filter;
        filter.page = page;

        const searchResult = await GetFilteredProducts(filter);
        let filteredProducts = [];
        let filteredSeries = [];
        let totalPages = 0;
        if (searchResult.error === true) {
            this.setState(prevState => ({...prevState, error: searchResult}));
        } else {
            filteredProducts = searchResult.result._embedded.products._embedded.page;
            totalPages = searchResult.result._embedded.products.page.totalPages;
            page = searchResult.result._embedded.products.page.number;
            filteredSeries = searchResult.result._embedded.series;
        }

        this.setState(prevState => ({
            ...prevState,
            products: filteredProducts,
            series: filteredSeries,
            filter: {...prevState.filter,
                        page: page,
                        totalPages: totalPages}
        }));

        this.setState({
            loading: false,
        }, () => this.scrollTo());
    }

    async loadRecommendations(accountId) {
        if (accountId) {
            let profilesResponse = await GetProfilesOfAccount(accountId);
            if (!profilesResponse.error) {
                const profiles = profilesResponse.result._embedded.profiles;
                const firstProfile = profiles[0];
                const selectedProfile = firstProfile.id;
                this.setState(prevState => ({
                    ...prevState,
                    selectedProfile: selectedProfile,
                    profiles: profiles,
                }));
                await this.getRecommendations(selectedProfile, firstProfile.ageRange.min, firstProfile.ageRange.max);
            }
        }
    }

    async getRecommendations(profileId, ageRangeMin , ageRangeMax ) {
        console.log("User changed profile to " + profileId);
        const response= await GetProfileRecommendations(profileId, ageRangeMin, ageRangeMax);
        if (!response.error === true && response.result?._embedded?.simpleHalRepresentationModels) {
            this.setState(prevState => ({...prevState,
                recommendations: response.result._embedded.simpleHalRepresentationModels}
            ));
        }
    }

    scrollTo() {
        window.scrollTo(0, 0)
        if (this.state.filter.query) {
            window.history.pushState({}, null, "/search/?query=" + this.state.filter.query
                    + (this.state.filter.language ? "&language=" + this.state.filter.language : ""));
        }
    }

    handlePageClick = (event) => {
        console.log("User requested page number " + event.selected);
        window.scrollTo(0, 0)
        this.setState(prevState => ({...prevState, page: event.selected}), () => this.searchProducts(event.selected));
    };

    handleSelectProfile = (selectedProfile) => {
        this.setState({
            selectedProfile: selectedProfile,
        }, () => {
            let ageRangeMin;
            let ageRangeMax;
            for (const profile of this.state.profiles) {
                if (profile.id === selectedProfile) {
                    ageRangeMin = profile.ageRange.min;
                    ageRangeMax = profile.ageRange.max;
                }
            }
            this.getRecommendations(selectedProfile, ageRangeMin, ageRangeMax);
        });
    };


    render() {
        const products = this.state.products;
        const series = this.state.series;

        const randomSeries = this.state.randomSeries;
        const recommendations = this.state.recommendations;
        const top10 = this.state.top10;
        const top10music = this.state.top10music;

        if(this.state.loading) {
            return (
                <div style={{width: "100%", height: "500px", textAlign: "center", fontSize: "20px"}}>
                    <br />
                    <br />
                    <br />
                    <TigerSpinner />
                    <br />
                    {Strings.PRODUCT_DETAILS_REFRESH} <span className="refresh-link" onClick={() => {
                        window.location.reload(false);
                }}>{Strings.PRODUCT_DETAILS_REFRESH_LINK}</span>
                </div>
            )
        }

        let options = [];
        if (this.state.profiles) {
            this.state.profiles.map(profile =>
                options.push({ label: profile.name, value: profile.id }),
            );
        }

        return (
            products.length > 0 || series.length > 0  ?
                <div className={"search-result"}>
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>{Strings.SEARCH_PAGE_TITLE}</title>
                        <meta name="description" content={Strings.SEARCH_PAGE_DESCRIPTION}/>
                        <meta name="keywords" content={Strings.SEARCH_PAGE_KEYWORDS}/>
                    </Helmet>

                    <Form onSubmit={() => {
                        this.searchProducts(0);
                    }}>

                        <div className="search-query-input-container">

                            <Form.Control type="search" value={this.state.filter.query}
                                          className="search-query-input"
                                      placeholder={Strings.PROFILE_PREFERENCES_SEARCH}
                                      onChange={(e) => this.setState(prevState => ({...prevState, filter: {
                                          ...prevState.filter,
                                          query: e.target.value
                                      }}))} />
                            <button id={"search-button"} type="submit" aria-label={Strings.SEARCH_BUTTON_TEXT}><FaSearch /></button>
                        </div>
{/*
                        <TigerButton className="w-100" variant="blue" type="submit" >
                            {Strings.SEARCH_BUTTON_TEXT}
                        </TigerButton>
*/}
                    </Form>

                    {series.length > 0  &&
                    <>
                    <div className="series-container">
                        <div>
                            <div className="search-title">
                                {Strings.SEARCH_RESULT_SERIES} (<a className="all-heros-headline-link" href={"/series/all"}>{Strings.SEARCH_RESULT_SERIES_LINK_TEXT}</a>)

                            </div>
                            <hr className={"search-hr"}/>
                        </div>
                        <div className="series-items-container-wrapper">
                            <div className="series-items-container">
                                {series.map(s => (
                                        <div className="series-item" key={s.id}>
                                            <SeriesResultItem series={s}/>
                                        </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    </>}

                    {products.length > 0 &&
                    <>
                    <div className="search-container">
                        <div>
                            <div className="search-title">
                                {Strings.SEARCH_RESULT_TITLE}
                            </div>
                            <hr className={"search-hr"}/>
                        </div>
                        <div className="search-items-container">
                            {products.map(p => (
                                <div className="search-item" key={p.id}>
                                    <SearchResultItem product={p}/>
                                </div>
                            ))}
                        </div>
                    </div>
                        <PaginationHelp
                            page={this.state.filter.page}
                            totalPages={this.state.filter.totalPages}
                            handlePageClick={this.handlePageClick}
                        />

                    </>}
                </div>
                :
                    <div className={"search-result"}>
                        <Helmet>
                            <meta charSet="utf-8"/>
                            <title>{Strings.SEARCH_PAGE_TITLE}</title>
                            <meta name="description" content={Strings.SEARCH_PAGE_DESCRIPTION}/>
                            <meta name="keywords"
                                  content={Strings.SEARCH_PAGE_KEYWORDS}/>
                        </Helmet>

                        <div className="search-query-input-container">
                            <Form onSubmit={() => {
                                this.searchProducts(0);
                            }}>
                                    <Form.Control type="search" value={this.state.filter.query}
                                                  placeholder={Strings.PROFILE_PREFERENCES_SEARCH}
                                                  className="search-query-input"
                                                  onChange={(e) => this.setState(prevState => ({...prevState, filter: {
                                                          ...prevState.filter,
                                                          query: e.target.value
                                                      }}))} />
                                    <button id={"search-button"} type="submit"><FaSearch /></button>
                            </Form>
                        </div>
                        {randomSeries.length > 0  &&
                                <div className="series-container">
                                    <div>
                                        <div className="search-title">
                                            Serien aus unserem Programm (<a className="all-heros-headline-link" href={"/series/all"}>alle</a>)
                                        </div>
                                        <hr className={"search-hr"}/>
                                    </div>
                                    <div className="series-items-container-wrapper">
                                        <div className="series-items-container">
                                            {randomSeries.map(s => (
                                                    <div className="series-item" key={s.id}>
                                                        <SeriesResultItem series={s}/>
                                                    </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                        }
                        {recommendations.length > 0 &&
                            <div className="search-container">
                                <div>
                                    <div className="search-title">
                                        {Strings.SEARCH_RECOMMENDATION_TITLE}
                                        {this.props.accountId && options.length > 0 &&
                                            <FormSelect className="recommendations-profile-select"
                                                        title={"recommendations-profile-select"}
                                                        value={this.state.selectedProfile}
                                                        onChange={(e) => this.handleSelectProfile(e.target.value)}>
                                                placeholder={Strings.CHOSE_PROFILE}
                                                >
                                                {options.map(option => (
                                                    <option value={option.value} key={option.value}>{option.label}</option>
                                                ))}
                                            </FormSelect>
                                    }
                                    </div>
                                    <hr className={"search-hr"}/>
                                </div>
                                <div className="search-items-container">
                                    {recommendations.map(p => (
                                        <div className="search-item" key={p.id}>
                                            <SearchResultItem product={p}/>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                        {top10.length > 0 &&
                            <div className="search-container">
                                <div>
                                    <div className="search-title">
                                        Top 10 Hörspiele/Hörbücher
                                    </div>
                                    <hr className={"search-hr"}/>
                                </div>
                                <div className="search-items-container">
                                    {top10.map(p => (
                                        <div className="search-item" key={p.id}>
                                            <SearchResultItem product={p}/>
                                        </div>
                                    ))}
                                </div>
                                </div>
                        }
                        {top10music.length > 0 &&
                                <div className="search-container">
                                    <div>
                                        <div className="search-title">
                                            Top 10 Musik
                                        </div>
                                        <hr className={"search-hr"}/>
                                    </div>
                                    <div className="search-items-container">
                                        {top10music.map(p => (
                                                <div className="search-item" key={p.id}>
                                                    <SearchResultItem product={p}/>
                                                </div>
                                        ))}
                                    </div>
                                </div>
                        }
                        {this.state.filter.query &&
                                <div className="search-container">
                                    <div className={"no-result-container"}>Deine Suche ergab leider keine Treffer</div>
                                </div>}

                    </div>
        );
    }
}

export default withToast(SearchResult);
