import gtmw from "./GoogleTagManagerWrapper";

class ConsentHandler {
    getCookieByName(name) {
        const allCookies = document.cookie.split("; ");
        const cookie = allCookies.find((c) => c.startsWith(`${name}=`));
        if (cookie) {
            try {
                return JSON.parse(decodeURIComponent(cookie.split("=")[1]));
            } catch {
                return null;
            }
        }
        return null;
    }

    sendDefaultConsent() {
        const defaultConsent = {
            ad_storage: "denied",
            ad_user_data: "denied",
            ad_personalization: "denied",
            analytics_storage: "denied",
            functionality_storage: "denied",
            personalization_storage: "denied",
            security_storage: "granted",
            wait_for_update: 500,
        };
        gtmw.sendDefaultConsentSignal(defaultConsent);
    }

    getConsentStatusFromCookie() {
        const consentCookie = this.getCookieByName("_tracking_consent");
        if (!consentCookie || !consentCookie.con?.CMP) {
            return {
                ad_storage: "denied",
                ad_user_data: "denied",
                ad_personalization: "denied",
                analytics_storage: "denied",
                functionality_storage: "denied",
                personalization_storage: "denied",
                security_storage: "granted",
            };
        }

        const CMP = consentCookie.con.CMP;

        return {
            ad_storage: CMP.m === "1" ? "granted" : "denied",
            ad_user_data: CMP.m === "1" ? "granted" : "denied",
            ad_personalization: CMP.m === "1" ? "granted" : "denied",
            analytics_storage: CMP.a === "1" ? "granted" : "denied",
            functionality_storage: CMP.s === "1" ? "granted" : "denied",
            personalization_storage: CMP.p === "1" ? "granted" : "denied",
            security_storage: "granted",
        };
    }

    mapConsentToPandectes(consentStatus) {
        return {
            C0000: consentStatus.security_storage === "granted" ? "allow" : "deny",
            C0001: consentStatus.functionality_storage === "granted" ? "allow" : "deny",
            C0002: consentStatus.analytics_storage === "granted" ? "allow" : "deny",
            C0003: consentStatus.ad_storage === "granted" ? "allow" : "deny",
        };
    }

    determinePandectesStatus(pandectesCategories) {
        const values = Object.values(pandectesCategories);
        if (values.every(v => v === "allow")) return "allow";
        if (values.every(v => v === "deny")) return "deny";
        if (values.includes("allow") && values.includes("deny")) return "mixed";
        return "deny";
    }

    sendUpdatedConsent() {
        const consentStatus = this.getConsentStatusFromCookie();

        const pandectesCategories = this.mapConsentToPandectes(consentStatus);
        const pandectesStatus = this.determinePandectesStatus(pandectesCategories);

        gtmw.updateConsent(consentStatus);

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: "Pandectes_Consent_Update",
            pandectes_status: pandectesStatus,
            pandectes_categories: pandectesCategories,
        });
    }

    init() {
        this.sendDefaultConsent();
        const consentStatus = this.getConsentStatusFromCookie();
        if (consentStatus) {
            this.sendUpdatedConsent();
        }
    }
}

export default new ConsentHandler();
