class GoogleTagManagerWrapper {
    constructor() {
        window.dataLayer = window.dataLayer || [];
        this.landingPageId = null;
        this.product = null;
    }
    sendDefaultConsentSignal(defaultConsent) {
        if (!window.gtag) {
            window.dataLayer = window.dataLayer || [];
            window.gtag = function () {
                window.dataLayer.push(arguments);
            };
        }
        window.gtag("consent", "default", defaultConsent);
    }

    updateConsent(consentValues) {
        if (!window.gtag) {
            window.dataLayer = window.dataLayer || [];
            window.gtag = function () {
                window.dataLayer.push(arguments);
            };
        }
        window.gtag("consent", "update", consentValues);
    }

    checkForProduct(log) {
        if (!this.product || !this.landingPageId) {
            //eslint-disable-next-line no-console
            if (log) console.error('GTM: ProductId or LandingPageId not set', this.productId, this.landingPageId);
            return false;
        }
        return true;
    }

    consentGoogleAnalytics() {
        if (window.dataLayer.filter((entry) => {return entry.event === 'consentGoogleAnalytics'}).length > 0) {
            return;
        }
        window.dataLayer.push({
            'event': 'consentGoogleAnalytics',
            'consentGoogleAnalytics': 'true'
        });
        window.dataLayer.push({
            'event': 'consentGoogleAds',
            'consentGoogleAds': 'true'
        });
        window.dataLayer.push({
            'event': 'consentFacebook',
            'consentFacebook': 'true'
        });
    }
   colorChosed(selectedColor) {
        window.dataLayer.push({
            'event': 'choose_box_color',
            'box_color': selectedColor
        });
    }


    login(user_id) {
        window.dataLayer.push({
            'event': 'login',
            'user_id': user_id
        });
    }


    registration(user_id) {
        window.dataLayer.push({
            'event': 'registration',
            'user_id': user_id
        });
    }

    checkoutOption(step, option) {
        if (!this.checkForProduct(true)) return;
        window.dataLayer.push({
            'event': 'checkoutOption',
            'ecommerce': {
                'checkout_option': {
                    'actionField': { 'step': step, 'option': option }
                }
            }
        });
    }

    checkout(step, option) {
        if (!this.checkForProduct(true)) return;
        let actionField = {};
        actionField.step = step;
        if (option) {
            actionField.option = option;
        }
        window.dataLayer.push({
            'event': 'checkout',
            'ecommerce': {
                'checkout': {
                    'actionField': actionField,
                    'products': [this.product]
                }
            }
        });

    }

    event(name, eventInfo, callback) {
        if (typeof callback !== 'function') {
            callback = () => { };
        }

        let productInfo = {};
        if (this.product) {
            productInfo.product = this.product;
            productInfo.landingPageId = this.landingPageId;
        }
        window.dataLayer.push({
            ...productInfo,
            ...eventInfo,
            'event': name,
        }, callback);
    }

    purchase(orderId, planVariantId, billwerkProductName, value, unit) {
        if (window.dataLayer.filter((entry) => {return entry.event === 'purchase'}).length > 0) {
            return;
        }
        window.dataLayer.push({
            'event': 'purchase',
            'ecommerce': {
                'transaction_id': orderId,
                'affiliation': "Tiger Website",
                'value' : value,
                'currency': unit,
                'items': [{
                    'item_id': planVariantId,
                    'item_name' : billwerkProductName,
                    'item_category' : 'Subscription'
                }]
            }
        });
    }

    purchaseDTT(transaction_id, value, items) {
        window.dataLayer.push({
            'event': 'purchase',
            'transaction_id': transaction_id,
            'value': value, 
            'currency': 'EUR',
            'items': items,
        });
    }

    addToCart() {
        window.dataLayer.push({
            'event': 'addToCart',
            'ecommerce': {
                'add': {
                    'products': [this.product]
                }
            }
        });
    }

    removeFromCart() {
        window.dataLayer.push({
            'event': 'removeFromCart',
            'ecommerce': {
                'remove': {
                    'products': [this.product]
                }
            }
        });
    }

    setLandingPageId(landingPageId) {
        this.landingPageId = landingPageId;
    }

    // Sends events after actively choosing a product (using a coupon, coming
    // from a landingpage or changing the product in last step).
    chooseProduct(billProduct, couponCode) {
        if (this.checkForProduct()) {
            this.removeFromCart();
        }
        this.setProduct(billProduct, couponCode);
        this.addToCart();
    }

    // Wont send any events. Is used internally or when the user presses F5
    // and the previous state is restored
    setProduct(billProduct, couponCode) {
        const { planVariantId, billwerkName, price, period, trial = {} , duration = {}} = billProduct;
        let quantity = duration.quantity != null ? duration.quantity : (period.quantity != null ? period.quantity : 1);
        let unit = duration.unit != null ? duration.unit : (period.unit != null ? period.unit : 'Month');
        const trialString = trial ? this.translatePeriod(trial.quantity, trial.unit) + ' Trial' : 'Keine Trial';
        const periodString =  this.translatePeriod(quantity, unit) + ' Laufzeit';
        this.product = {
            id: planVariantId,
            name: billwerkName,
            price: price,
            variant: `${periodString}, ${trialString}`,
            quantity: 1,
            coupon: couponCode || '',
            category: this.landingPageId
        };
    }

    translatePeriod(quantity, unit) {
        if (quantity != null && unit != null) {
            if (unit === 'day') {
                return quantity > 1 ? quantity + " Tage" : "1 Tag";
            } else if (unit === 'week') {
                return quantity > 1 ? quantity + " Wochen" : "1 Woche";
            } else if (unit === 'month') {
                return quantity > 1 ? quantity + " Monate" : "1 Monat";
            } else if (unit === 'year') {
                return quantity > 1 ? quantity + " Jahre" : "1 Jahr";
            } else {
                return "";
            }
        } else {
            return "";
        }
    }

    noPurchase() {
        if (this.checkForProduct()) {
            this.removeFromCart();
        }
        this.product = null;
        this.landingPageId = null;
    }

    //Is called, when the user starts a new purchase through coupon or landingPage
    newPurchase(landingPageId, product, couponCode, sendAlreadyLoggedInEvent) {
        this.setLandingPageId(landingPageId);
        this.chooseProduct(product, couponCode);
        this.checkout(1);
        if (sendAlreadyLoggedInEvent) {
            this.checkoutOption(1, 'alreadyLoggedin');
            this.checkout(2);
        }
    }

    //Is called, when the previous state is restored after page refresh (F5)
    loadPurchase(landingPageId, product, couponCode) {
        this.setLandingPageId(landingPageId);
        this.setProduct(product, couponCode || '');
    }


    addTopic(titleName) {
        window.dataLayer.push({
            event: 'add_topic',
            title_name: titleName,
        });
    }
    removeTopic(titleName) {
        window.dataLayer.push({
            event: 'remove_topic',
            title_name: titleName,
        });
    }
    addHero(titleName) {
        window.dataLayer.push({
            event: 'add_hero',
            title_name: titleName,
        });
    }
    removeHero(titleName) {
        window.dataLayer.push({
            event: 'remove_hero',
            title_name: titleName,
        });
    }
    addSeries(titleName) {
        window.dataLayer.push({
            event: 'add_series',
            title_name: titleName,
        });
    }
    removeSeries(titleName) {
        window.dataLayer.push({
            event: 'remove_series',
            title_name: titleName,
        });
    }
    addCluster(titleName) {
        window.dataLayer.push({
            event: 'add_cluster',
            title_name: titleName,
        });
    }
    removeCluster(titleName) {
        window.dataLayer.push({
            event: 'remove_cluster',
            title_name: titleName,
        });
    }
    addWildcard(wildcards) {
        window.dataLayer.push({
            event: "add_wildcard",
            name: wildcards
        });
    }
    addWatchlist(title_name) {
        window.dataLayer.push({
            event: "add_watchlist",
            title_name: title_name
        });
    }
    removeWatchlist(title_name) {
        window.dataLayer.push({
            event: "remove_watchlist",
            name: title_name
        });
    }
    addTicket(duration) {
        window.dataLayer.push({
            event: "redeem_ticket",
            item_variant: duration
        });
    }
    userInfo(user_id) {
        window.dataLayer.push({
            event: "user_id",
            user_id:user_id
        });
    }
    shippingInfo(contact) {
        window.dataLayer.push({
            event: "add_shipping_info",
            contact: {
                firstName: contact.firstName,
                lastName: contact.lastName,
                street: contact.street,
                houseNumber: contact.houseNumber,
                zip: contact.zip,
                city: contact.city,
                country: contact.country,
                email: contact.email
            }
        });
    }
    paymentMethodInfo(paymentMethod) {
        window.dataLayer.push({
            event: "add_payment_info",
            payment_method: paymentMethod
        });
    }
    beginCheckout(purchaseData) {
        if (!purchaseData || !purchaseData.planVariantId) {
            return;
        }
        window.dataLayer.push({ ecommerce: null }); // Clear previous ecommerce data
        window.dataLayer.push({
            event: "begin_checkout",
            ecommerce: {
                items: [{
                    item_id: purchaseData.planVariantId ,
                    item_name: purchaseData.billwerkProductName ,
                    currency: purchaseData.currency,
                    item_variant: purchaseData.value,
                    price: purchaseData.price,
                    quantity: purchaseData.quantity
                }]
            }
        });
    }

}

let gtmw = new GoogleTagManagerWrapper();
export default gtmw;