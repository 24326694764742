const Strings = {

    //GLOBAL
    GLOBAL_TIME: "Uhr",

    // NAVIGATION
    NAV_DASHBOARD: "Übersicht",
    NAV_AUDIOBOOKS: "Zuletzt gehört",
    NAV_TIGERBOXES: "tigerboxen",
    NAV_TIGERTICKETS: "Meine tigertones-Tickets",
    NAV_WILDCARDS: "wildcards",
    NAV_SUBSCRIPTIONS: "Alle Zugänge",
    NAV_WATCHLIST: "Merkliste",
    NAV_ACCOUNT: "Einstellungen",
    NAV_PREMIUM_SETTINGS: "Meine Zahlungsdaten",
    NAV_ACCOUNT_PASSWORD: "Passwort ändern",
    NAV_ACCOUNT_LOGOUT: "Abmelden",
    NAV_LOGIN: "Anmelden",
    NAV_REGISTER: "Registrierung",
    NAV_LANGUAGE: "Sprache",
    NAV_LANGUAGE_ENGLISH: "English",
    NAV_LANGUAGE_GERMAN: "Deutsch",
    NAV_MY_PROFILE: "Mein Profil",
    NAV_PREMIUM_ACCESS: "Zugänge",
    NAV_REDEEM_TICKET: "tigertones-Ticket einlösen",
    NAV_MY_LISTS: "Meine Listen",
    NAV_MY_PRODUCTS: "Meine Produkte",
    NAV_ALL_PRODUCTS: "Alle Produkte",
    NAV_PREMIUM: "Mein Zugang",

    //TIGERPOST B2B
    TIGERPOST_B2B_TITLE: "tigerpost-Anmeldung",
    TIGERPOST_B2B_INFO: "Du willst keine Neuigkeiten rund um das tigerversum verpassen? Dann melde dich jetzt für unseren Newsletter <strong>tigerpost</strong> an!",
    TIGERPOST_B2B_SUCCESS: "Danke für deine Anmeldung. Du solltest in Kürze eine E-Mail erhalten. Bitte schaue in deinem E-Mail-Postfach nach.",
    TIGERPOST_B2B_ERROR: "Ein Fehler ist aufgetreten. Bitte versuche es gleich noch einmal oder wende dich an unseren Support.",
    TIGERPOST_B2B_BUTTON: "Jetzt anmelden!",

    UNSUBSCRIBE_TIGERPOST_B2B_TITLE: "tigerpost-Abmeldung",
    UNSUBSCRIBE_TIGERPOST_B2B_INFO: "Schade, dass du unsere tigerpost nicht mehr erhalten möchtest. Trage bitte hier deine E-Mail-Adresse ein, um dich abzumelden.",
    UNSUBSCRIBE_TIGERPOST_B2B_SUCCESS: "Deine Abmeldung von der tigerpost war erfolgreich.",
    UNSUBSCRIBE_TIGERPOST_B2B_ERROR: "Ein Fehler ist aufgetreten. Bitte versuche es gleich noch einmal oder wende dich an unseren Support.",
    UNSUBSCRIBE_TIGERPOST_B2B_BUTTON: "Jetzt abmelden",

    //CONTRACT CANCELLATION
    CONTRACT_CANCELLATION_HEADLINE: "Dein Kündigungswunsch",
    CONTRACT_CANCELLATION_RETRIEVAL_HINT: "Falls es etwas gibt, mit dem wir dich vom Bleiben überzeugen können, schreib uns doch gerne eine E-Mail an <a href='mailto:support@tiger.media' style='color: #ce023e'>support@tiger.media</a> mit dem Betreff „Meine Kündigung“ und lass uns wissen, was wir für dich tun können.",
    CONTRACT_CANCELLATION_INFO_1: "Hier kannst du dein <b>tigertones- oder tigerbooks-Abo</b> sowie dein <b>Kombi-Paket</b> kündigen. Die Kündigung eines tigertones-Tickets ist nicht nötig, da es automatisch endet.<br/>" +
            "<br/>" +
            "Solltest du noch Fragen haben, ist unser tigermedia-Support unter <a href='mailto:support@tiger.media' style='color: #ce023e'>support@tiger.media</a> gerne für dich da.",
    CONTRACT_CANCELLATION_INFO_2: "Hinweis: Wenn du ein tigertones-Ticket hast, ist keine Kündigung nötig, da dieses automatisch endet.",
    CONTRACT_CANCELLATION_INFO_3: "Du möchtest den Zugang zu tigertones lieber mit einem Ticket (keine automatische Verlängerung) freischalten?",
    CONTRACT_CANCELLATION_FORM_CREATED_DATE: "Eingang des Kündigungswunsches:",
    CONTRACT_CANCELLATION_FORM_CREATED_DATE_PLACEHOLDER: "Datum und Uhrzeit werden automatisch beim Absenden eingetragen",
    CONTRACT_CANCELLATION_FORM_TYPE: "Welche Art der Kündigung möchtest du aussprechen?",
    CONTRACT_CANCELLATION_FORM_PRODUCT_TYPE: "Was möchtest du kündigen?",
    CONTRACT_CANCELLATION_INFO_TYPE: "Mit einer <b>ordentlichen Kündigung</b> beendest du deinen Vertrag regulär zum Ende der Mindestlaufzeit bzw. zum Ende der aktuellen Abrechnungsperiode. Wenn du innerhalb des Gratiszeitraums ordentlich kündigst, endet dein Vertrag entsprechend zum Ende des Testzeitraums.<br/>" +
            "<br/>" +
            "Für eine <b>außerordentliche Kündigung</b> zu sofort und somit vor Ablauf der Mindestlaufzeit deines Vertrages muss ein entsprechender Grund vorliegen.",
    CONTRACT_CANCELLATION_FORM_CANCELLATION_DATE: "Gewünschter Kündigungszeitpunkt:",
    CONTRACT_CANCELLATION_FORM_REASON: "Kündigungsgrund:",
    CONTRACT_CANCELLATION_FORM_EMAIL: "Deine E-Mail-Adresse:",
    CONTRACT_CANCELLATION_FORM_SELECT_PLACEHOLDER: "Bitte wählen …",
    CONTRACT_CANCELLATION_FORM_NOTE_PLACEHOLDER: "Bitte teile uns den Grund deiner Kündigung mit",
    CONTRACT_CANCELLATION_FORM_EXTRAORDINARY_REASON: "Grund der außerordentlichen Kündigung:",
    CONTRACT_CANCELLATION_FORM_TYPE_OPTION_ON_TIME: "Ordentliche Kündigung",
    CONTRACT_CANCELLATION_FORM_TYPE_OPTION_REASON: "Außerordentliche Kündigung",
    CONTRACT_CANCELLATION_FORM_TYPE_REASON_PLACEHOLDER: "Bitte gib den Grund an*",
    CONTRACT_CANCELLATION_FORM_PRODUCT_TYPE_OPTION_BUNDLE: "Kombi-Paket",
    CONTRACT_CANCELLATION_FORM_PRODUCT_TYPE_OPTION_SUBSCRIPTION: "tigertones- oder tigerbooks-Abo",
    CONTRACT_CANCELLATION_FORM_PRODUCT_TYPE_OPTION_TICKET: "tigertones-Ticket",
    CONTRACT_CANCELLATION_FORM_PRODUCT_TYPE_OPTION_BOOKS: "tigerbooks-Abo",
    CONTRACT_CANCELLATION_FORM_DATE_OPTION_NEXT: "Frühestmöglich",
    CONTRACT_CANCELLATION_FORM_DATE_OPTION_SELECTED: "Wunschdatum wählen:",
    CONTRACT_CANCELLATION_FORM_PLATFORM: "Wo hast du dein Abo bzw. Kombi-Paket abgeschlossen?",
    CONTRACT_CANCELLATION_DATE_PICKER_TODAY: "Heute",
    CONTRACT_CANCELLATION_SUBMIT: "Jetzt Vertrag verbindlich kündigen",
    CONTRACT_CANCELLATION_ERROR_EMAIL: "Bitte gib eine gültige E-Mail-Adresse an",
    CONTRACT_CANCELLATION_ERROR_PRODUCT_TYPE: "Bitte wähle das zu kündigende Abo bzw. Kombi-Paket aus",
    CONTRACT_CANCELLATION_ERROR_REASON: "Bitte gib den Grund der außerordentlichen Kündigung an",
    CONTRACT_CANCELLATION_END_OF_CONTRACT: "Ende der Vertragslaufzeit:",
    CONTRACT_CANCELLATION_TICKET_LINK: "Jetzt Ticket sichern",
    CONTRACT_CANCELLATION_EMAIL_HINT: "Bitte gib hier deine tigertones-E-Mail-Adresse an. An diese E-Mail-Adresse senden wir ebenfalls deine Kündigungsbestätigung.",
    CONTRACT_CANCELLATION_PRODUCT_HINT: "Bitte wähle aus, welches Abo du kündigen möchtest. Bitte beachte: Wenn du ein Abo über den App Store bzw. den Google Store abgeschlossen hast, " +
        "logge dich in den jeweiligen Store ein und deaktiviere die automatische Verlängerung. Bitte bedenke dabei, dass du mindestens 24 Stunden vor Erneuerung der Laufzeit kündigen musst.",
    CONTRACT_CANCELLATION_PRODUCT_TICKET_HINT: "Hinweis: Bei einem Ticket ist keine Kündigung nötig, da dieses automatisch endet.",
    CONTRACT_CANCELLATION_CUSTOMER_NOT_FOUND: "Dein Kündigungswunsch ist eingegangen, wir konnten allerdings kein aktives tigertones-Website-Abo oder Kombi-Paket für die angegebene E-Mail-Adresse {email} finden. Bitte beachte: Wenn du ein tigertones-In-App-Abo über den App Store bzw. den Google Store abgeschlossen hast, logge dich in den jeweiligen Store ein und deaktiviere die automatische Verlängerung.",
    CONTRACT_CANCELLATION_NO_CONTRACTS_FOUND: "Dein Kündigungswunsch ist eingegangen, wir konnten allerdings kein aktives tigertones-Website-Abo oder Kombi-Paket für die angegebene E-Mail-Adresse {email} finden. Bitte beachte: Wenn du ein tigertones-In-App-Abo über den App Store bzw. den Google Store abgeschlossen hast, logge dich in den jeweiligen Store ein und deaktiviere die automatische Verlängerung.",
    CONTRACT_CANCELLATION_NO_ACTIVE_CONTRACT_FOUND: "Dein Kündigungswunsch ist eingegangen, wir konnten allerdings kein aktives tigertones-Website-Abo oder Kombi-Paket für die angegebene E-Mail-Adresse {email} finden. Bitte beachte: Wenn du ein tigertones-In-App-Abo über den App Store bzw. den Google Store abgeschlossen hast, logge dich in den jeweiligen Store ein und deaktiviere die automatische Verlängerung.",
    CONTRACT_CANCELLATION_NO_CONTRACT_OF_PRODUCT_TYPE_FOUND: "Dein Kündigungswunsch ist eingegangen, wir konnten allerdings kein aktives tigertones-Website-Abo oder Kombi-Paket für die angegebene E-Mail-Adresse {email} finden. Bitte beachte: Wenn du ein tigertones-In-App-Abo über den App Store bzw. den Google Store abgeschlossen hast, logge dich in den jeweiligen Store ein und deaktiviere die automatische Verlängerung.",
    CONTRACT_CANCELLATION_UNKNOWN_ERROR: "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es gleich noch einmal oder wende dich an den Support unter support@tiger.media. <br /><br />Bitte beachte: Wenn du ein tigertones-In-App-Abo über den App Store bzw. den Google Store abgeschlossen hast, logge dich in den jeweiligen Store ein und deaktiviere die automatische Verlängerung.",
    CONTRACT_CANCELLATION_MODAL_HEADER: "Hinweis",
    CONTRACT_CANCELLATION_PLATFORM_WEB: "tigermedia-Website",
    CONTRACT_CANCELLATION_PLATFORM_APP: "tigertones-App / Apple App Store / Google Play Store",
    CONTRACT_CANCELLATION_PLATFORM_HINT: "<strong>Bitte beachte:</strong> Wenn du ein tigertones-In-App-Abo über den <strong>App Store</strong> bzw. den <strong>Google Play Store</strong> abgeschlossen hast, melde dich im jeweiligen Store an und deaktiviere die automatische Verlängerung. Auf unserer Website kannst du nur kündigen, wenn du dein tigertones-Website-Abo auch direkt über unsere Website abgeschlossen hast.<br /><br />" +
        "Hier geht’s zum Google Play Store: <a href='http://support.google.com/googleplay?p=cancelsubsawf'><strong>Zum Google Play Store</strong></a> <br />" +
        "Hier geht’s zum Apple App Store: <a href='https://apps.apple.com/account/subscriptions'><strong>Zum Apple App Store</strong></a>",

    CANCELLATION_CONFIRMED_HEADLINE: "Eingangsbestätigung Kündigungswunsch",
    CANCELLATION_CONFIRMED_INFO: "Wir haben deinen Kündigungswunsch am {dateTime} über unser Kündigungsformular unter {url} erhalten und " +
        "bestätigen dir den Eingang. Wir kümmern uns umgehend darum, ihn zu bearbeiten.<br /><br />" +
        "Wir schicken dir eine Kündigungsbestätigung per E-Mail an die von dir angegebene Adresse. Bitte prüfe deinen E-Mail-Eingang sowie deinen Spam-Ordner. " +
        "Solltest du innerhalb von 24h keine Bestätigungsmail erhalten haben, wende dich bitte an den <a href='https://helpcenter.tiger.media/hc/de/requests/new' style='color: #CE023E'>Tiger-Support</a>.",
    CANCELLATION_CONFIRMED_INFO_2: "Folgende Angaben hast du gemacht:",
    CANCELLATION_CONFIRMED_CANCELLATION_DATE_TYPE: "Wunschdatum: ",
    CANCELLATION_CONFIRMED_DOWNLOAD_PDF: "Als PDF speichern",
    CANCELLATION_CONFIRMED_SURVEY_HEADLINE: "Wir werden dich vermissen!",

    CONTRACT_CANCELLATION_REASON_OPTION_TOO_EXPENSIVE: "Das Abo bzw. Kombi-Paket  ist zu teuer",
    CONTRACT_CANCELLATION_REASON_OPTION_NOT_ENOUGH_CONTENT: "Es gibt nicht genug Inhalte für ältere Kinder",
    CONTRACT_CANCELLATION_REASON_OPTION_CONTENT: "Ich bin mit dem Hörspielangebot unzufrieden",
    CONTRACT_CANCELLATION_REASON_OPTION_TICKETS: "Ich nutze lieber tigertones-Tickets",
    CONTRACT_CANCELLATION_REASON_OPTION_TECHNICAL_PROBLEMS: "Es gibt zu viele technische Probleme",
    CONTRACT_CANCELLATION_REASON_OPTION_TIGERTONES: "Mir gefällt tigertones als App nicht (Suchfunktion, Aufbau etc.)",
    CONTRACT_CANCELLATION_REASON_OPTION_AVAILABILITY: "tigertones ist außerhalb der DACH-Staaten nicht verfügbar",
    CONTRACT_CANCELLATION_REASON_OPTION_OTHER: "Sonstiges:",

    //PDF
    CANCELLATION_PDF_TITLE: "Eingangsbestätigung Kündigungswunsch",
    CANCELLATION_PDF_INFO_1: "Wir haben deinen Kündigungswunsch am {dateTime} über unser Kündigungsformular unter {url} erhalten und " +
        "bestätigen dir den Eingang. Wir kümmern uns umgehend darum, ihn zu bearbeiten.",
    CANCELLATION_PDF_INFO_2: "Wir schicken dir eine Kündigungsbestätigung per E-Mail an die von dir angegebene Adresse. Bitte prüfe deinen E-Mail-Eingang sowie deinen Spam-Ordner. " +
        "Solltest du innerhalb von 24h keine Bestätigungsmail erhalten haben, wende dich bitte an den Kunden-Support.",
    CANCELLATION_PDF_INFO_3: "Solltest du Rückfragen haben, erreichst du uns über unser Kontaktformular unter {supportUrl}.",
    CANCELLATION_PDF_INFO_4: "Folgende Angaben hast du gemacht:",
    CANCELLATION_PDF_INFO_5: "Tigerstarke Grüße,",
    CANCELLATION_PDF_INFO_6: "Dein tigermedia-Team",
    CANCELLATION_PDF_ADDRESS_1: "Tiger Media Deutschland GmbH",
    CANCELLATION_PDF_ADDRESS_2: "Eppendorfer Baum 23",
    CANCELLATION_PDF_ADDRESS_3: "20249 Hamburg",
    CANCELLATION_PDF_ADDRESS_4: "E-Mail:",
    CANCELLATION_PDF_EMAIL: "Kontaktformular Support",
    CANCELLATION_PDF_ADDRESS_5: "Web:",
    CANCELLATION_PDF_WEB: "www.tiger.media",
    CANCELLATION_PDF_OWNER_1: "Geschäftsführer: Till Weitendorf, Martin Kurzhals",
    CANCELLATION_PDF_OWNER_2: "Registergericht: Hamburg",
    CANCELLATION_PDF_OWNER_3: "Registernummer: HRB 136212",
    CANCELLATION_PDF_OWNER_4: "Umsatzsteuer-Identifikationsnummer gemäß",
    CANCELLATION_PDF_OWNER_5: "§27a Umsatzsteuergesetz: DE301141329",
    CANCELLATION_PDF_AGB: "AGB",
    CANCELLATION_PDF_DATENSCHUTZ: "Datenschutz",
    CANCELLATION_PDF_HELP: "Hilfe",
    CANCELLATION_PDF_CANCELLED_PRODUCT: 'Gekündigtes Abo bzw. Kombi-Paket :',

    //RECALLS
    RECALL_DEVICE_CHECK_TITLE: "(Title)",
    RECALL_DEVICE_CHECK_INFO: "(InfoText)",
    RECALL_DEVICE_DEVICE_ID: "Geräte-ID:",
    RECALL_DEVICE_DEVICE_ID_HINT: "Die Geräte-ID deiner tigerbox TOUCH findest du im Elternbereich",
    RECALL_DEVICE_DEVICE_CREDENTIALS: "Geräte-ID:",
    RECALL_DEVICE_DEVICE_CREDENTIALS_HINT: "Die Geräte-ID findest du auf dem Aufkleber auf der Unterseite deiner tigerbox TOUCH",
    RECALL_DEVICE_DEVICE_BATCH_NO: "Batch Code:",
    RECALL_DEVICE_DEVICE_BATCH_NO_HINT: "Bitte gib die ersten zwei Stellen deines Batch Codes an. Den Code findest du auf dem Aufkleber auf der Unterseite deiner tigerbox Touch.",
    RECALL_NO_RECALL_REQUIRED: "Deine Box ist nicht von der Rückrufaktion betroffen",
    RECALL_ERROR_ALREADY_SUBMITTED: "Die Rückrufanfrage ist für diese Box bereits eingegangen und wird bearbeitet.",
    RECALL_ERROR_DEVICE_INFO_REQUIRED: "Bitte gib mindestens ein Feld an",
    RECALL_UNKNOWN_ERROR: "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es gleich noch einmal oder wende dich an den Support unter support@tiger.media.",
    RECALL_CHECK_BUTTON: "Jetzt überprüfen",
    RECALL_CONTINUE_BUTTON: "Fortfahren",
    RECALL_SKIP_CHECK_BUTTON: "Überprüfung überspringen",
    RECALL_IS_VALID_INFO: "Deine Box ist vom Rückruf betroffen. Bitte klicke auf den folgenden Button, um fortzufahren.",
    RECALL_DEVICE_CHECK_TITLE_2: "Jetzt Retoure anmelden und neue tigerbox Touch erhalten",
    RECALL_DEVICE_CHECK_INFO_2: "Deine tigerbox TOUCH ist betroffen? Natürlich erhältst du von uns direkt eine neue Box! Fülle dazu einfach die Versandinformationen aus:",
    RECALL_DEVICE_FIRST_NAME: "Vorname:*",
    RECALL_DEVICE_LAST_NAME: "Nachname:*",
    RECALL_DEVICE_EMAIL: "E-Mail-Adresse:*",
    RECALL_DEVICE_STREET: "Straße & Hausnummer:*",
    RECALL_DEVICE_POSTAL_CODE: "Postleitzahl:*",
    RECALL_DEVICE_CITY: "Stadt:*",
    RECALL_DEVICE_COUNTRY: "Land:*",
    RECALL_DEVICE_COLOR: "Farbe der betroffen Box:*",
    RECALL_SUBMIT_BUTTON: "Jetzt abschicken",
    RECALL_DEVICE_FIELD_REQUIRED: "Bitte fülle dieses Feld aus",
    RECALL_DEVICE_SELECT_BOX_COLOR: "Bitte wähle die Farbe der Box, die du zurückschickst",
    RECALL_DEVICE_VALID_EMAIL_REQUIRED: "Bitte gib eine gültige E-Mail-Adresse an",
    RECALL_DEVICE_SELECT_COUNTRY: "Bitte wähle ein Land aus",
    RECALL_COUNTRY_GERMANY: "Deutschland",
    RECALL_COUNTRY_SWISS: "Schweiz",
    RECALL_COUNTRY_AUSTRIA: "Österreich",
    RECALL_DEVICE_BATCH_NO_NOT_FOUND: "Ich habe keinen Zugriff auf meinen Batch Code",
    RECALL_DEVICE_ID_OR_CREDENTIALS_INFO: "Wenn du keinen Zugriff auf deine Batch Nummer hast, kannst du alternativ die Geräte-ID verwenden.<br /><br />" +
        "Für die Überprüfung reicht es, wenn nur eines der Felder ausgefüllt wird.",
    RECALL_DEVICE_ID_OR_CREDENTIALS: "oder",

    RECALL_CONFIRMATION_TITLE: "Das hat geklappt!",
    RECALL_CONFIRMATION_INFO: "Eine neue tigerbox TOUCH ist auf dem Weg zu dir!",
    RECALL_CONFIRMATION_NAME: "Name:",
    RECALL_CONFIRMATION_EMAIL: "E-Mail-Adresse:",
    RECALL_CONFIRMATION_STREET: "Straße & Hausnummer:",
    RECALL_CONFIRMATION_CITY: "Ort:",
    RECALL_CONFIRMATION_COUNTRY: "Land:",
    RECALL_CONFIRMATION_COLOR: "Farbe der Box, die du zurückschickst:",


    //TITLES
    TITLE_DASHBOARD: "Übersicht",
    TITLE_TIGERBOXES: "Tigerboxen",
    TITLE_WILDCARDS: "Wildcards",
    TITLE_WATCHLIST: "Merkliste",
    TITLE_AUDIOBOOKS: "Zuletzt gehört",
    TITLE_PROFILES: "Profile",

    //TICKET REDEMPTION
    TICKET_REDEMPTION_DASHBOARD_LINK: "Zurück zu „Mein Konto“",
    TICKET_REDEMPTION_TITLE: "Hier kannst du dein Ticket einlösen!",
    TICKET_REDEMPTION_TICKET_NO: "Zugangscode:",
    TICKET_REDEMPTION_TICKET_PIN: "PIN:",
    TICKET_REDEMPTION_SUBMIT_CODE_PIN: "Bestätigen",
    TICKET_REDEMPTION_SUBMIT_CREDENTIALS: "Abschicken",
    TICKET_REDEMPTION_CANCEL: "Abbrechen",
    TICKET_REDEMPTION_HINT: "Löse jetzt dein Ticket ein",
    TICKET_REDEMPTION_UNKNOWN_ERROR: "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es später noch einmal.",
    TICKET_REDEMPTION_PREMIUM_INFO_TITLE: "Dein tigertones-Ticket steht bereit",
    TICKET_REDEMPTION_PREMIUM_INFO_1: "Deine Laufzeit verlängert sich um <strong><span style='color: #ce023e'>{duration}</span></strong> bis zum <strong><span style='color: #ce023e'>{endDate}</span></strong>. Möchtest du das <strong><span style='color: #ce023e'>{type}</span></strong> jetzt einlösen?",
    TICKET_REDEMPTION_PREMIUM_INFO_2: "Durch Klicken auf „Einlösen“ stimme ich den ",
    TICKET_REDEMPTION_PREMIUM_AGB: "AGB",
    TICKET_REDEMPTION_PREMIUM_AND: "",
    TICKET_REDEMPTION_PREMIUM_NUTZUNGSBEDINGUNGEN: "",
    TICKET_REDEMPTION_PREMIUM_ZU: "zu",
    TICKET_REDEMPTION_REDEEM: "Jetzt einlösen",
    TICKET_REDEMPTION_SUCCESS_1: "Jetzt kannst du loslegen und das ganze tigerversum mit deinem tigertones-Ticket entdecken. Spannende Abenteuer und Geschichten warten schon darauf, von dir entdeckt zu werden! Bitte beachte, dass es wenige Minuten dauert, bis dein neuer Zugang in der Übersicht angezeigt wird.",
    TICKET_REDEMPTION_SUCCESS_2: "Lade die App auf dein Smartphone oder starte deine tigerbox, um direkt loszulegen!!",
    TICKET_REDEMPTION_FINISH_BUTTON: "Zurück zur Übersicht",

    // GLOBAL
    STATUS_ACTIVE: "Aktiv",
    STATUS_INACTIVE: "Inaktiv",
    MODAL_CLOSE_BUTTON: "Schließen",
    BACK_BUTTON: "Zurück",
    CANCEL_BUTTON: "Abbrechen",
    GLOBAL_ERROR_TITLE: "Hoppla, ein Fehler ist aufgetreten.",
    GLOBAL_ERROR_MESSAGE: "Beim Laden der Daten ist ein Fehler aufgetreten. Bitte lade die Seite neu oder ",
    GLOBAL_ERROR_LINK: "logge dich erneut ein",

    //PRODUCT DETAILS
    PRODUCT_DETAILS_ERROR_TITLE: "Hoppla, ein Fehler ist aufgetreten.",
    PRODUCT_DETAILS_ERROR_MESSAGE: "Der gesuchte Titel konnte leider nicht gefunden werden. Klicke ",
    PRODUCT_DETAILS_ERROR_LINK: "hier, um zur Hauptseite zurückzukehren.",
    PRODUCT_DETAILS_REFRESH: "Falls du nicht automatisch weitergeleitet wirst, lade die Seite neu oder",
    PRODUCT_DETAILS_REFRESH_LINK: "klicke bitte hier …",
    PRODUCT_DETAILS_CTA: "Klingt spannend? Dann hole dir jetzt ein Ticket oder Abo für tigertones und genieße auf deiner <a href=\"https://tiger.media/pages/tigerbox\">tigerbox</a> oder in der tigertones-App über 20.000 Hörspielen, Hörbüchern und Musikalben!",
    PRODUCT_DETAILS_CTA_TICKET: "tigertones-Tarife entdecken",
    PRODUCT_DETAILS_CTA_SUBSCRIPTION: "Jetzt Abo abschließen",

    //PLAYLISTS
    PLAYLIST_ERROR_TITLE: "Die Playlist ist leer.",
    PLAYLIST_ERROR_MESSAGE: "In dieser Playlist sind keine Hörspiele vorhanden. Klicke ",
    PLAYLIST_ITEM_AGE: "ab {0} Jahren",

    //SEARCH
    SEARCH_PAGE_TITLE: "Suche für tigertones und die tigerbox :: tiger.media",
    SEARCH_PAGE_DESCRIPTION: "Finde heraus welche Hörspiele, Hörbücher und Kindermusik es für deinen tigertones-Zugang auf der tigerbox gibt und suche nach deinen Lieblings-Hörspielen, Serien und -hörbuchern",
    SEARCH_PAGE_KEYWORDS: "Suche,Serie,Hörspiel,Hörbuch,Kinder,tigerbox,Musik,Folgen,top 10,beliebt",
    SEARCH_ERROR_TITLE: "Kein Suchergebnis",
    SEARCH_ERROR_MESSAGE: "Deine Suche ergab kein Ergebnis",
    SEARCH_ITEM_AGE: "<span>{0}+</span>",
    SEARCH_BUTTON_TEXT: "Suchen",
    SEARCH_EPISODE_STANDARD_NAME: "Folge",
    SEARCH_TITLE_INCLUDES_ONE_MORE_EPISODES_TEXT: "weitere Episode",
    SEARCH_TITLE_INCLUDES_MULTIPLE_EPISODES_TEXT: "weitere Episoden",
    SEARCH_RESULT_TITLE: "Titel",
    SEARCH_RESULT_SERIES: "Serien, Heldinnen und Helden",
    SEARCH_RESULT_SERIES_LINK_TEXT: "alle",
    SEARCH_RECOMMENDATION_TITLE: "Empfehlungen für ",

    //SERIES
    SERIES_DETAILS_AGE: "Alter:<br/><span>{0}+</span>",
    SERIES_INFORMATION_HEADLINE: "Informationen zur Serie",
    SERIES_HERO_AVAILABLE: "Als Held auf deiner tigerbox verfügbar",
    SERIES_NO_EPISODES: "Serie enthält keine Episoden",
    SERIES_HERO_HINT: "Füge jetzt diese Serie als Lieblingsheld zum ausgewählten Profil hinzu",
    SERIES_HERO_NO_LOGIN_HINT: "Diese Serie ist als Held auf deiner tigerbox verfügbar. <a href=\"/login?goto=/series/{0}\">Melde dich an,</a> um diese Serie als Lieblingsheld auszuwählen.",

    //AUTHENTICATION
    AUTHENTICATION_TITLE: "Anmeldung",
    AUTHENTICATION_INFO: "Bitte melde dich an, um dein tigertones-Konto zu verwalten.",
    AUTHENTICATION_INFO_NEW_ACCOUNT: "Deine Registrierung war erfolgreich. Bitte melde dich jetzt an, um dein tigertones-Konto zu verwalten.",
    AUTHENTICATION_EMAIL: "E-Mail-Adresse",
    AUTHENTICATION_PASSWORD: "Passwort",
    AUTHENTICATION_SUBMIT: "Anmelden",

    AUTHENTICATION_ERROR_BAD_CREDENTIALS: "Deine E-Mail-Adresse oder dein Passwort ist nicht korrekt",
    AUTHENTICATION_ERROR_INVALID_EMAIL: "E-Mail ist leer oder ungültig",
    AUTHENTICATION_ERROR_BLANK_PASSWORD: "Passwort ist erforderlich",

    AUTHENTICATION_REGISTER_QUESTION: "Du hast noch kein Konto?",
    AUTHENTICATION_REGISTER_LINK: "Jetzt kostenlos registrieren!",

    //REGISTRATION
    REGISTER_FORM_TITLE: "Registrieren",
    REGISTER_FORM_INFO: "Registriere dich jetzt, um das ganze tigerversum zu entdecken.",
    REGISTER_FORM_EMAIL: "E-Mail-Adresse",
    REGISTER_FORM_PASSWORD: "Passwort",
    REGISTER_FORM_PASSWORD_REQUIREMENTS: "(Min. 8 Zeichen)",
    REGISTER_FORM_PASSWORD_CONFIRMATION: "Passwort bestätigen",
    REGISTER_FORM_CHECKBOX_NEWSLETTER: "Ja, ich möchte mehr über Neuigkeiten (tigerpost) und Features (tigertipps) erfahren und persönliche Content-Empfehlungen erhalten. Meine Einwilligung kann ich jederzeit widerrufen.",
    REGISTER_FORM_REGISTER_BUTTON: "Jetzt registrieren",
    REGISTER_FORM_LOGIN_HINT: "Du bist bereits registriert?",
    REGISTER_FORM_LOGIN_LINK: "Anmelden",
    REGISTER_EMAIL_IN_USE: "Diese E-Mail-Adresse wird bereits verwendet",
    REGISTER_PASSWORT_TO_SHORT: "Das Passwort muss eine Länge zwischen 8 und 50 Zeichen haben",
    REGISTER_UNKNOWN_ERROR_TITLE: "Hoppla!",
    REGISTER_UNKNOWN_ERROR_BODY: "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es später erneut oder wende dich an unseren Support.",
    REGISTER_SUCCESS_TITLE: "Das hat geklappt!",
    REGISTER_SUCCESS_INFO: "Du kannst dich jetzt mit deinen Benutzerdaten anmelden. Bitte schaue auch in deinem Postfach nach, um deine E-Mail-Adresse zu bestätigen.",


    // PASSWORD FORGOT
    FORGOT_PASSWORD_TITLE: "Passwort vergessen?",
    FORGOT_PASSWORD_INFO: "Gib deine E-Mail-Adresse ein. Wir senden dir einen Link zum Zurücksetzen deines Passwortes.",
    FORGOT_PASSWORD_EMAIL: "E-Mail-Adresse",
    FORGOT_PASSWORD_SUBMIT: "Passwort zurücksetzen",

    FORGOT_PASSWORD_ERROR: "Ein Fehler ist aufgetreten, Bitte später nochmals versuchen.",
    FORGOT_PASSWORD_ERROR_INVALID_EMAIL: "E-Mail ist leer oder ungültig",

    FORGOT_PASSWORD_LOGIN_LINK: "Zurück zur Anmeldung",

    // EMAIL CHANGE
    CHANGE_EMAIL_TITLE: "E-Mail-Adresse ändern",
    CHANGE_EMAIL_INFO: "Hier kannst du deine E-Mail-Adresse ändern",
    CHANGE_EMAIL_NEW: "Neue E-Mail",
    CHANGE_EMAIL_CONFIRMATION: "Neue E-Mail bestätigen",
    CHANGE_EMAIL_SUBMIT: "E-Mail ändern",

    CHANGE_EMAIL_ERROR: "Ein Fehler ist aufgetreten, Bitte später nochmals versuchen.",
    CHANGE_EMAIL_ERROR_NOT_MATCH: "E-Mail-Adressen stimmen nicht überein",

    // PASSWORD CHANGE
    CHANGE_PASSWORD_TITLE: "Passwort ändern",
    CHANGE_PASSWORD_INFO: "Bitte gib dein altes Passwort und ein neues Passwort ein. Das Passwort sollte eine Länge zwischen 8 und 50 Zeichen haben.",
    CHANGE_PASSWORD_OLD: "Altes Passwort",
    CHANGE_PASSWORD_NEW: "Neues Passwort",
    CHANGE_PASSWORD_CONFIRMATION: "Neues Passwort bestätigen",
    CHANGE_PASSWORD_SUBMIT: "Passwort ändern",

    CHANGE_PASSWORD_ERROR: "Ein Fehler ist aufgetreten, Bitte später nochmals versuchen.",
    CHANGE_PASSWORD_ERROR_INVALID_PASSWORD: "Passwort ist ungültig",
    CHANGE_PASSWORD_ERROR_NOT_MATCH: "Passwörter stimmen nicht überein",

    CHANGE_PASSWORD_DASHBOARD_LINK: "Zurück zum Dashboard",

    // PASSWORD RESET
    RESET_PASSWORD_TITLE: "Passwort zurücksetzen",
    RESET_PASSWORD_INFO: "Bitte gib ein neues Passwort an. Das neue Passwort sollte eine Länge von 8 bis 50 Zeichen haben.",
    RESET_PASSWORD_FIRST: "Neues Passwort",
    RESET_PASSWORD_CONFIRM: "Neues Passwort bestätigen",
    RESET_PASSWORD_SUBMIT: "Passwort zurücksetzen",
    RESET_PASSWORD_ERROR: "Ein Fehler ist aufgetreten, bitte später nochmals versuchen. Eventuell ist der Link in der E-Mail nicht mehr gültig.",
    RESET_PASSWORD_TOKEN_ERROR: "Ein Fehler ist aufgetreten. Bitte klicke noch einmal auf den Link in der E-Mail.",
    RESET_PASSWORD_ERROR_LENGTH: "Das Passwort muss eine Länge zwischen 8 und 50 Zeichen haben",
    RESET_PASSWORD_ERROR_MATCH: "Die Passwörter stimmen nicht überein",
    RESET_PASSWORD_LOGIN_LINK: "Zurück zur Anmeldung",
    RESET_PASSWORD_SHOW: "Passwort anzeigen",
    RESET_PASSWORD_HIDE: "Passwort verbergen",

    // DASHBOARD
    DASHBOARD_ACCOUNT_CARD_LABEL: "Mein Benutzerkonto verwalten",
    DASHBOARD_PREMIUM_CARD_LABEL: "Meine Zugänge verwalten",
    DASHBOARD_NEWSLETTER_CARD_LABEL: "Meine tigermedia-Newsletter",
    DASHBOARD_WILDCARDS_CARD_LABEL: "Meine wildcards verwalten",
    DASHBOARD_PROFILES_CARD_LABEL: "Meine Profile verwalten",
    DASHBOARD_DEVICES_CARD_LABEL: "Meine tigerboxen verwalten",
    DASHBOARD_NUMBER_PROFILES: "PROFILE",
    DASHBOARD_NUMBER_AUDIOBOOKS: "Zuletzt gehört",
    DASHBOARD_NUMBER_CLUSTERS: "Helden und Welten",
    DASHBOARD_NUMBER_WATCHLIST: "Merkliste",
    DASHBOARD_NUMBER_TIGERBOXES: "TIGERBOX",
    DASHBOARD_ACCOUNT_SETTINGS: "BENUTZERKONTO",
    DASHBOARD_NUMBER_ACTIVE_TIGERTICKETS: "Aktive tigertones-Tickets",
    DASHBOARD_NUMBER_WILDCARDS: "WILDCARDS",
    DASHBOARD_NUMBER_ACTIVE_SUBSCRIPTIONS: "Aktives Abo",
    DASHBOARD_PREMIUM_ACCESS: "TIGERTONES",
    DASHBOARD_NO_RECENTLY_USED_PRODUCTS: "Deine Liste ist noch leer",
    DASHBOARD_RECENTLY_USED_PRODUCTS_TOOLTIP: "Noch nichts gehört? Entdecke jetzt über 20.000 Hörspiele und Lieder in unserer tigertones App und auf der tigerbox.",
    DASHBOARD_CLUSTERS_EMPTY: "Deine Liste ist noch leer",
    DASHBOARD_CLUSTERS_TOOLTIP: "Noch keine Helden oder Welten ausgewählt? Füge jetzt deine Lieblingshelden und -welten hinzu.",
    DASHBOARD_WATCHLIST_EMPTY: "Deine Liste ist noch leer",
    DASHBOARD_WATCHLIST_TOOLTIP: "Verwende die Suche, um deine Lieblingstitel oder neue Hörabenteuer zu entdecken und speichere diese für später ab.",
    DASHBOARD_NO_ACTIVE_SUBSCRIPTION: "Kein aktiver tigertones-Zugang",
    DASHBOARD_SUBSCRIPTION_ACTIVE: "Abo aktiv",
    DASHBOARD_TIGERTICKET_ACTIVE: "tigertones-Ticket aktiv",
    DASHBOARD_NO_ACTIVE_TIGERBOX: "Keine tigerbox vorhanden",
    DASHBOARD_TIGERBOX_TOOLTIP: "Verbinde deine tigerbox mit deinem tigertones-Konto und genieße alle tigertones-Inhalte über die Box.",
    DASHBOARD_NO_WILDCARDS: "Keine wildcards vorhanden",
    DASHBOARD_WILDCARDS_TOOLTIP: "Hier Inhalte als MP3- oder AAC-Datei hochladen oder über die tigertones-App eigene Inhalte aufnehmen.",
    DASHBOARD_REDEEM_TICKET_1: "Du hast bereits ein Ticket? Dann löse es hier direkt ein. ",
    DASHBOARD_REDEEM_TICKET_2: "In unserem Shop kannst du dir ein neues tigertones-Ticket kaufen, um sofort Zugriff auf über 20.000 Titel zu erhalten.",
    DASHBOARD_NEWSLETTER: "NEWSLETTER",
    DASHBOARD_NEWSLETTER_INFO_TIGERPOST_1: "Tigerstark, du hast unsere tigertipps abonniert!",
    DASHBOARD_NEWSLETTER_INFO_TIGERPOST_2: "Du willst aber auch keine Neuigkeiten rund um das tigerversum verpassen? Dann melde dich jetzt für unseren Newsletter tigerpost an!",
    DASHBOARD_NEWSLETTER_INFO_TIGERTIPPS_1: "Tigerstark, du bist für die tigerpost angemeldet!",
    DASHBOARD_NEWSLETTER_INFO_TIGERTIPPS_2: "Abonniere jetzt noch unsere tigertipps. Hier erfährst du in elf E-Mails alles zu den Features und Funktionen rund ums tigerversum.",
    DASHBOARD_NEWSLETTER_INFO_TIGERPOST_AND_TIGERTIPPS_1: "Du willst keine Neuigkeiten rund um das tigerversum verpassen? Dann melde dich jetzt für unseren Newsletter tigerpost an!",
    DASHBOARD_NEWSLETTER_INFO_TIGERPOST_AND_TIGERTIPPS_2: "Abonniere außerdem unsere tigertipps. Hier erfährst du in elf E-Mails alles zu den Features und Funktionen rund ums tigerversum.",
    DASHBOARD_NEWSLETTER_COMPLETE_1: "Tigerstark, du hast die tigerpost und die tigertipps abonniert!",
    DASHBOARD_NEWSLETTER_COMPLETE_2: "Damit erhältst du nicht nur nützliche Informationen zu den Features und Funktionen, sondern verpasst auch keine Neuigkeiten rund ums tigerversum.",

    DASHBOARD_NEWSLETTER_UPDATE_SUCCESS: "Danke. Bitte schaue in deinem E-Mail-Postfach nach, um deine Anmeldung zu bestätigen.",
    DASHBOARD_NEWSLETTER_UPDATE_ERROR: "Ups. Ein Fehler ist aufgetreten. Bitte versuche es später noch einmal oder wende dich an den Support.",
    DASHBOARD_NEWSLETTER_UPDATE_WARNING: "Bitte wähle zuerst einen Newsletter aus",

    DASHBOARD_PREMIUM_STATUS_LABEL: "Status:",
    DASHBOARD_PREMIUM_STATUS_VALUE_ACTIVE: "aktiv",
    DASHBOARD_PREMIUM_STATUS_VALUE_INACTIVE: "inaktiv",
    DASHBOARD_PREMIUM_TYPE_LABEL: "Art:",
    DASHBOARD_PREMIUM_TYPE_VALUE_SUPPORT: "Support",
    DASHBOARD_PREMIUM_TYPE_VALUE_SUB_BILLWERK_TONES: "tigertones-Website-Abo (tigermedia)",
    DASHBOARD_PREMIUM_TYPE_VALUE_SUB_BILLWERK_BOOKS: "tigerbooks-Website-Abo (tigermedia)",
    DASHBOARD_PREMIUM_TYPE_VALUE_SUB_IAP_APPLE_TONES: "tigertones-In-App-Abo (Apple)",
    DASHBOARD_PREMIUM_TYPE_VALUE_SUB_IAP_GOOGLE_TONES: "tigertones-In-App-Abo (Google)",
    DASHBOARD_PREMIUM_TYPE_VALUE_SUB_IAP_APPLE_BOOKS: "tigerbooks-In-App-Abo (Apple)",
    DASHBOARD_PREMIUM_TYPE_VALUE_SUB_IAP_GOOGLE_BOOKS: "tigerbooks-In-App-Abo (Google)",
    DASHBOARD_PREMIUM_TYPE_VALUE_TICKET: "tigertones-Ticket (Prepaid)",
    DASHBOARD_PREMIUM_TYPE_VALUE_SWISS_TICKET: "Schweizer Ticket (Prepaid)",
    DASHBOARD_PREMIUM_DURATION_LABEL: "Laufzeit:",
    DASHBOARD_PREMIUM_DURATION_VALUE_SUB_UNCANCELLED: "Verlängert sich am {0}",
    DASHBOARD_PREMIUM_DURATION_VALUE_SUB_CANCELLED: "<strong><span style='color: #CE023E'>Endet am {0}</span></strong>",
    DASHBOARD_PREMIUM_DURATION_VALUE_SUB_IAP: "Bis zum {0}",
    DASHBOARD_PREMIUM_PRODUCT_LABEL: "Zugang zu:",
    DASHBOARD_PREMIUM_PRODUCT_VALUE_TIGERTONES: "tigertones",
    DASHBOARD_PREMIUM_PRODUCT_VALUE_TIGERBOOKS: "tigerbooks",
    DASHBOARD_PREMIUM_SUBSCRIPTION_OVERVIEW: "Deine Abos (Übersicht)",
    DASHBOARD_PREMIUM_INVOICES_OVERVIEW: "Deine Rechnungen",
    DASHBOARD_PREMIUM_CANCEL_SUBSCRIPTION_BUTTON: "Abo kündigen",
    DASHBOARD_PREMIUM_NEW_TICKET_BUTTON: "Neues Ticket",
    DASHBOARD_PREMIUM_REDEEM_TICKET_BUTTON: "Ticket einlösen",
    DASHBOARD_PREMIUM_DURATION_VALUE_TICKET: "<strong><span>Endet am {0}</span></strong>",
    DASHBOARD_PREMIUM_TO_TICKET_BUTTON: "Zum Ticket",
    DASHBOARD_PREMIUM_TO_SUBSCRIPTION_BUTTON: "Zum Abo",
    DASHBOARD_PREMIUM_INACTIVE_INFO: "Schalte jetzt Zugang zu tigertones frei und lausche über 20.000 Titeln!",
    DASHBOARD_PREMIUM_IAP_INFO: "Zur Verwaltung (Rechnung, Kündigung) deines <strong>tigertones-In-App-Abos</strong> gehe in den Google Play oder App Store",
    DASHBOARD_PREMIUM_EXTEND_ACCESS_BUTTON: "Jetzt Zugang verlängern",
    DASHBOARD_PREMIUM_MAINTAIN_BUTTON: "Zugang verwalten",

    DASHBOARD_PREMIUM_SUPPORT: "Dein Zugang wurde über den Support freigeschaltet. Wenn du Fragen dazu hast, melde dich gerne bei uns.",
    DASHBOARD_ACCOUNT_EMAIL_LABEL: "E-Mail-Adresse:",
    DASHBOARD_ACCOUNT_CHANGE_EMAIL: "E-Mail-Adresse ändern ",
    DASHBOARD_ACCOUNT_CHANGE_PASSWORD: "Passwort ändern ",
    DASHBOARD_ACCOUNT_LOGOUT: "Abmelden",
    //NEWSLETTER
    NEWSLETTER_ALL_ACTIVE: "Hurra! Du bist bereits für unsere Newsletter angemeldet und damit immer auf dem Laufenden im tigerversum!",
    NEWSLETTER_PARTIAL_ACTIVE: "Kennst du unsere weiteren Newsletter? Melde dich jetzt an und bleibe immer auf dem Laufenden!",
    NEWSLETTER_NO_ACTIVE: "Nichts mehr verpassen!",
    NEWSLETTER_CARD_INFO: "Bleibe immer auf dem Laufenden!",
    NEWSLETTER_CARD_SELECT: "Zur Auswahl",
    NEWSLETTER_ERROR: "Beim Laden unserer Newsletter ist ein Fehler aufgetreten, bitte versuche es gleich erneut.",

    NEWSLETTER_SUBSCRIBE_BUTTON: "Jetzt anmelden",
    NEWSLETTER_UNSUBSCRIBE_BUTTON: "Abmelden",

    NEWSLETTER_TIGERPOST_NAME: "tigerpost",
    NEWSLETTER_TIGERPOST_DESCRIPTION: "Neuigkeiten, Gewinnspiele",
    NEWSLETTER_TIGERTIPPS_NAME: "tigertipps",
    NEWSLETTER_TIGERTIPPS_DESCRIPTION: "Features und Funktionen",
    NEWSLETTER_CONTENT_NAME: "Content-News",
    NEWSLETTER_CONTENT_DESCRIPTION: "Neue Folgen/Inhalte",
    NEWSETTER_EMAIL_REQUIRED: "Bitte gib deine E-Mail-Adresse an",

    NEWSLETTER_SUBSCRIBE_TITLE: "Du entscheidest, welche E-Mails du von tigermedia erhalten möchtest.",
    NEWSLETTER_SUBSCRIBE_TIGERPOST_DESCRIPTION: "Du willst keine Neuigkeiten rund um das tigerversum verpassen? Solche Benachrichtigung erhältst du alle ein bis zwei Wochen.",
    NEWSLETTER_SUBSCRIBE_TIGERTIPPS_DESCRIPTION: "Bei den tigertipps erhältst du für elf Wochen jede Woche eine E-Mail zu allen Features und Funktionen deiner Tiger-Produkte.",
    NEWSLETTER_SUBSCRIBE_CONTENT_NEWS_DESCRIPTION: "Verpasse zudem keine neue Folge deiner ausgewählten Helden und Welten – mit unseren <span style='font-weight: bold;'>Content-News</span>! " +
        "Du erhältst diese Benachrichtigung ein Mal pro Woche, sofern neue Folgen verfügbar sind.",
    NEWSLETTER_UNSUBSCRIBE_TITLE: "Schade, dass du unseren Newsletter nicht mehr erhalten möchtest.",
    NEWSLETTER_UNSUBSCRIBE_SUCCESS: "Du wurdest vom Newsletter abgemeldet",

    NAV_NEWSLETTER: "Newsletter",

    //ACCOUNT DELETION
    ACCOUNT_DELETION_INFO: "Schade, dass du gehen möchtest!",
    ACCOUNT_DELETION_BUTTON: "tigertones-Konto löschen",

    // AUDIOBOOKS
    AUDIOBOOKS_REMOVE_BUTTON_LABEL: "Löschen",
    AUDIOBOOKS_MODAL_REMOVE_TITLE: "Titel aus der Liste löschen",
    AUDIOBOOKS_MODAL_REMOVE_TEXT: "Möchtest du diesen Titel wirklich aus der Liste löschen?",
    AUDIOBOOKS_MODAL_REMOVE_BUTTON_LABEL: "Löschen",

    AUDIOBOOKS_REMOVE_SUCCESS: "Der Titel wurde erfolgreich aus der Liste entfernt",
    AUDIOBOOKS_REMOVE_ERROR_TRY_AGAIN: "Titel konnten nicht entfernt werden. Bitte später nochmals versuchen.",
    AUDIOBOOKS_REMOVE_ERROR: "Beim Entfernen des Titels ist ein Fehler aufgetreten. Fehlermeldung: ",
    
    AUDIOBOOKS_NOTHING_FOUND: "Liste der zuletzt gehörten Titel ist leer",

    //ACCOUNT ACTIVATION
    ACCOUNT_ACTIVATION_TITLE: "Geschafft!",
    ACCOUNT_ACTIVATION_BODY: "Deine Registrierung ist abgeschlossen und deine E-Mail-Adresse erfolgreich bestätigt!<br />" +
        "Mit diesem tigertones-Konto kannst du beide Apps nutzen und die tigermedia-Inhalte genießen.<br />" +
        "Jetzt <a href='https://tiger.media/pages/tigertones'><span style='text-decoration: underline'>tigertones</span></a> oder <a href='https://tiger.media/tigerbooks'><span style='text-decoration: underline'>tigerbooks</span></a> herunterladen!",
    ACCOUNT_ACTIVATION_TITLE_ERROR: "Huch!",
    ACCOUNT_ACTIVATION_BODY_ERROR: "Der tigertones-Konto  konnte nicht aktiviert werden. Bitte versuche es gleich noch einmal.",
    ACCOUNT_ACTIVATION_BODY_MISSING_KEY: "Der Aktivierungslink ist leider ungültig. Bitte versuche es erneut.",

    //EMAIL CHANGED
    EMAIL_CHANGED_TITLE: "Geschafft!",
    EMAIL_CHANGED_BODY: "Deine E-Mail-Adresse wurde erfolgreich geändert.<br />" +
        "Du kannst dich nun mit der E-Mail-Adresse und deinem Passwort beide Apps nutzen und die tigermedia-Inhalte genießen.<br />" +
        "Jetzt <a href='https://tiger.media/pages/tigertones'><span style='text-decoration: underline'>tigertones</span></a> oder <a href='https://tiger.media/tigerbooks'><span style='text-decoration: underline'>tigerbooks</span></a> herunterladen!",
    EMAIL_CHANGED_TITLE_ERROR: "Huch!",
    EMAIL_CHANGED_BODY_ERROR: "Die neue E-Mail-Adresse konnte nicht bestätigt werden. Bitte versuche es gleich noch einmal.",
    EMAIL_CHANGED_BODY_MISSING_KEY: "Der Bestätigungslink ist leider ungültig. Bitte versuche es erneut.",

    // SUBSCRIPTIONS
    SUBSCRIPTIONS_LABEL_PURCHASED: "Gekauft am",
    SUBSCRIPTIONS_LABEL_EXPIRES: "Gültig bis zum",
    SUBSCRIPTIONS_LABEL_DAYS_LEFT: "Tage übrig",
    SUBSCRIPTIONS_LABEL_1_DAY_LEFT: "1 Tag übrig",

    SUBSCRIPTION_MANAGE_PLAYSTORE: "Im Playstore verwalten",
    SUBSCRIPTION_MANAGE_APPLESTORE: "Im Appstore verwalten",
    SUBSCRIPTION_MANAGE_BILLWERLK: "Abo verwalten",

    SUBSCRIPTIONS_SORY_BY: "Sortieren nach",
    SUBSCRIPTIONS_SORT_EXPIRY_DATE: "Ablaufdatum",
    SUBSCRIPTIONS_SORT_PURCHASE_DATE: "Kaufdatum",
    SUBSCRIPTIONS_NOTHING_FOUND: "Keine Abos oder tigertones-Tickets gefunden",
    SUBSCRIPTIONS_SHOW_ACTIVE: "Nur aktive Abos anzeigen ",
    SUBSCRIPTIONS_SHOW_ALL: "Alle Abos anzeigen",

    SUBSCRIPTIONS_ERROR_UNKNOWN_PRODUCT: "Unbekanntes Produkt",

    // PREMIUM
    PREMIUM_INFO: "<span style='color: #ec8320; font-weight: bold'>Bitte beachte:</span><br /> Hier werden nur diejenigen <strong>tigertones-Website-Abos</strong> angezeigt, " +
        "die du <strong>über die tigermedia-Website abgeschlossen</strong> hast.<br /> tigertones-In-App-Abos über Google oder Apple sowie Tickets (= Prepaid, kein Abo) sind hier nicht aufgeführt.",

    PREMIUM_NO_CONTENT: "Es liegen keine tigertones-Website-Abos vor, die über die tigermedia-Website abgeschlossen wurden.<br /><br />" +
        "<span style='color: #ec8320; font-weight: bold'>Bitte beachte:</span> tigertones-In-App-Abos über Google und Apple sowie Tickets (= Prepaid, kein Abo) sind hier nicht aufgeführt.",
    PREMIUM_LOADING_ERROR: "Beim Laden der tigertones-Daten ist ein Fehler aufgetreten. Bitte versuche es später noch einmal oder wende dich an den Support.",
    PREMIUM_START_DATE: "Laufzeit vom: ",
    PREMIUM_END_DATE: "bis:",
    PREMIUM_NEXT_TARIF: "Nächster Zugang:",
    PREMIUM_NEXT_TARIF_FROM: "ab",
    PREMIUM_DOWNLOAD_BUTTON: "Herunterladen",
    PREMIUM_CONTRACT_ACTIVE: "Aktiv",
    PREMIUM_CONTRACT_INACTIVE: "Beendet",
    PREMIUM_CONTRACT_INACTIVE_SINCE: "Beendet am {0}",
    PREMIUM_CONTRACT_NO: "Abo (Vertragsnr.: {0})",
    PREMIUM_NO_CONTRACT_SELECTED: "Bitte wähle den Vertrag aus, dessen Rechnungen du sehen möchtest.",
    PREMIUM_CONTRACT_HAS_NO_INVOICES: "Es liegen keine Rechnungen vor",
    PREMIUM_CONTRACT_START_DATE: "abgeschlossen am {0}",
    PREMIUM_CONTRACT_NEXT_BILLING_DATE: "verlängert sich am {0}",
    PREMIUM_CONTRACT_END_DATE_PAST: "abgelaufen am {0}",
    PREMIUM_CONTRACT_END_DATE_FUTURE: "endet am {0}",
    PREMIUM_INVOICE_DATE: "Rechnung vom {0}",
    PREMIUM_INVOICE_NO: "Rechnungsnummer: {0}",

    PREMIUM_HEADER_PHASE_TYPE_STATUS: "Status:",
    PREMIUM_HEADER_PHASE_TYPE_INAKTIV: "Kein aktiver tigertones-Zugang vorhanden",
    PREMIUM_HEADER_PHASE_TYPE_TICKET: "Aktiv bis:",
    PREMIUM_HEADER_PHASE_TYPE_SUBSCRIPTION: "Aktuell bis:",
    PREMIUM_HEADER_PHASE_TYPE_CANCELLED: "Endet am:",
    PREMIUM_HEADER_NEXT_BILL_DATE: "Nächste Abrechnung:",
    PREMIUM_TICKET_HEADER_LABEL_TYPE: "Typ:",
    PREMIUM_TICKET_COUPON_TYPE_DDF: "Die drei ??? VIP-Pass",
    PREMIUM_TICKET_COUPON_TYPE_SWISS_UPGRADE: "Schweizer Upgrade",
    PREMIUM_TICKET_COUPON_TYPE_HUI_BUH: "Hui Buh VIP-Pass",
    PREMIUM_TICKET_HEADER_LABEL_UNUSED: "Deine gekauften Tickets",
    PREMIUM_TICKET_HEADER_LABEL_TICKETS: "Deine eingelösten Tickets",
    PREMIUM_TICKET_HEADER_LABEL_COUPONS: "Deine eingelösten Pässe und Gutscheine",
    PREMIUM_TICKET_TABLE_DURATION: "Laufzeit:",
    PREMIUM_TICKET_TABLE_REDEMPTION_DATE: "Eingelöst am:",
    PREMIUM_TICKET_TABLE_TYPE: "Typ:",
    PREMIUM_TICKET_TABLE_COUPON_NAME: "Name:",
    PREMIUM_TICKET_TABLE_TICKET_CODE: "Zugangscode:",
    PREMIUM_TICKET_TABLE_PASS_CODE: "Pass-Nr.:",
    PREMIUM_TICKET_TABLE_MORE_DETAILS: "Weitere Informationen ",
    PREMIUM_TICKET_TABLE_OPEN_LANDING_PAGE: "Zur Veranstaltungsseite ",
    PREMIUM_TICKET_NO_TICKETS: "Auf diesem Benutzerkonto wurden noch keine Tickets eingelöst",
    PREMIUM_TICKET_REDEEM_TICKET_BUTTON: "Jetzt einlösen ",

    PREMIUM_NO_ACTIVE_ACCESS_INFO: "Du hast ",

    PREMIUM_IAP_TYPE_VARIOUS: "tigertones-In-App-Abo",
    PREMIUM_IAP_TYPE_APPLE: "Apple-Abo",
    PREMIUM_IAP_TYPE_GOOGLE: "Google-Abo",
    PREMIUM_TYPE_SHOP_SUBSCRIPTION: "tigertones-Website-Abo",
    PREMIUM_IAP_INFO_MANAGE: "&#187; Dein {0} kannst du <a href='{1}'>hier</a> verwalten.",
    PREMIUM_SHOW_PRODUCTS_LINK: "tigertones-Produkte anzeigen ",
    PREMIUM_SHOW_TICKET_REDEMPTION_LINK: "Neues Ticket einlösen",
    PREMIUM_SHOW_ALL_INVOICES_LINK: "Alte Rechnungen anzeigen ",
    PREMIUM_SHOW_ALL_TICKETS_LINK: "Meine eingelösten Tickets anzeigen ",
    PREMIUM_SHOW_CURRENT_TYPE: "Meinen aktuellen Zugang anzeigen",
    PREMIUM_INVOICES_LABEL: "Rechnungen",
    PREMIUM_INVOICES_DOWNLOAD_BUTTON: "Herunterladen",
    PREMIUM_SHOW_CANCEL_CONTRACT: "Abo kündigen ",
    PREMIUM_NO_INVOICES: "Für dieses Benutzerkonto existieren keine Rechnungen",

    PREMIUM_SETTINGS_ADRESS: "Rechnungsadresse",
    PREMIUM_SETTINGS_PAYMENT: "Zahlungsdaten",
    PREMIUM_PAYMENT_CC: "Kreditkarte",
    PREMIUM_PAYMENT_PP: "PayPal",
    PREMIUM_PAYMENT_SEPA: "Lastschrift",
    PREMIUM_PAYMENT_CC_NUMBER: "Karten-Nr.: ",
    PREMIUM_PAYMENT_CC_TYPE: "Typ: ",
    PREMIUM_PAYMENT_CC_VALIDITY: "Gültig bis: ",
    PREMIUM_SETTINGS_EDIT: "Daten bearbeiten ",
    PREMIUM_PAYMENT_SEPA_HOLDER: "Kontoinhaber: ",
    PREMIUM_PAYMENT_SEPA_IBAN: "IBAN: ",
    PREMIUM_PAYMENT_SEPA_BIC: "BIC: ",
    PREMIUM_PAYMENT_PAYPAL_EMAIL: "E-Mail: ",
    PREMIUM_PAYMENT_PAYPAL_FIRSTNAME: "Vorname: ",
    PREMIUM_PAYMENT_PAYPAL_LASTNAME: "Nachname: ",
    PREMIUM_PAYMENT_NO_DATA: "Es wurden noch keine Lastschrift- oder Kreditkarteninformationen angegeben.<br />Klicke auf den folgenden Link, um Zahlungsdaten hinzuzufügen.",

    PREMIUM_INACTIVE_TICKET_HEADER: "digitales Ticket",
    PREMIUM_INACTIVE_TICKET_INFO: "Jetzt wird Streaming zum Kinderspiel. Mit dem digitalen Ticket hast du bis zu 12 Monate freien Zugriff auf die beliebtesten " +
        "Hörbücher, Hörspiele und Musik für Kids.<br /><br />" +
        "Entdecke auf deiner tigerbox die große, bunte Hörspaßwelt von tigertones. In vielen verschiedenen Themen-Welten findest du alle Abenteuer und " +
        "Kinderlieder passend zu deinen Vorlieben in einer tigerstarken Playlist. Mit dabei sind bspw. Detektiv-Geschichten, Prinzessinnen-Stories, Dino-Abenteuer, " +
        "unsere Märchenwelt, für Superhelden, die Piraten-Insel, unser Reiterhof und noch ganz viele mehr.",
    PREMIUM_INACTIVE_SUBSCRIPTION_HEADER: "tigertones-Abo",
    PREMIUM_INACTIVE_SUBSCRIPTION_INFO: "Bei tigertones gibt es jede Menge Musik und das größte Angebot ausgewählter Hörspiele und Hörbücher für Kinder. " +
        "Mit über 20.000 Titeln ist für jeden Geschmack für Kinder von 4–12 Jahren etwas dabei. Die beliebtesten Kinderhelden, die tollsten Geschichten " +
        "und die bekanntesten Lieder zum Mitsingen und Tanzen – tigertones macht einfach Spaß.",
    PREMIUM_INACTIVE_SHOP_BUTTON: "Zum Shop",
    PREMIUM_INACTIVE_REDEEM_TICKET_BUTTON: "Ticket einlösen",

    //REDIRECT
    DEEPLINK_REDIRECT_BROWSER_TAB: "tigertones :: tiger media",
    DEEPLINK_REDIRECT_OPEN_TONES: "In tigertones öffnen",
    DEEPLINK_REDIRECT_INSTALL_TONES: "tigertones installieren",
    DEEPLINK_REDIRECT_TO_APP: "Zur App",

    //DDF
    DDF_REDEMPTION_LABEL_CODE: "CODE:",
    DDF_REDEMPTION_LABEL_PIN: "PIN:",
    DDF_ERROR_TITLE: "Oh, was ist denn hier passiert ?<span style='color: #e92f23'>?</span>" +
        "<span style='color: #02abeb'>?</span>",
    DDF_ERROR_INFO_1: "Wir konnten deine VIP-Hörspiel-Pass-Informationen leider nicht laden.",
    DDF_ERROR_INFO_2: "Bitte versuche es erneut, indem du deinen Zugangscode und den PIN manuell eingibst oder wende dich an " +
        "<a href='https://tiger.media/hilfe/'>unseren Support</a>.",
    DDF_ERROR_BUTTON: "Informationen zum VIP-Hörspiel-Pass eingeben",
    DDF_REDEMPTION_BROWSER_TAB: "Die drei ??? :: tiger.media",
    DDF_REDEMPTION_HEADLINE: "Willkommen auf der Einlöseseite<br/>deines VIP Hörspiel-Passes.<br/><br/>" +
            "Du hast den Die drei ???-Hörspielpass noch nicht?<br/>Hier erhältlich:<br/><a href='https://tiger.media/products/tigercard-die-drei-live-und-der-dunkle-taipan'>tigermedia Shop</a> und" +
            "<a href='https://www.thalia.de/shop/home/artikeldetails/A1067284480'>Thalia</a>",
    DDF_REDEMPTION_CODE_PLACEHOLDER: "Bitte gib den Zugangscode ein",
    DDF_REDEMPTION_PIN_PLACEHOLDER: "Bitte gib die PIN ein",
    DDF_REDEMPTION_INFO: "Der VIP Hörspiel-Pass ermöglicht dir den Zugriff auf alle Live-Mitschnitte der Die drei ??? Live Tour 2022 (Nicht enthalten: Waldbühne Berlin und Zürich).<br /><br />" +
        "Gib dazu einfach Zugangscode und PIN von der Rückseite deines VIP Hörspiel-Passes ein!",
    DDF_REDEMPTION_HEADLINE_2: "EXKLUSIVER<br/><span style='color: #e92f23'>VIP-</span><span style='color: #02abeb'>ZUGANG</span>",
    DDF_REDEMPTION_INFO_2: "Der VIP Hörspiel-Pass ist ausschließlich an den Merchandising-Ständen während der Live-Shows erhältlich.<br /><br />" +
        "Spätestens <strong>24 Stunden nach jeder Show</strong> steht jeder Live-Mitschnitt der Die drei ??? Tour 2022 exklusiv in der tigertones-Audiothek zur Verfügung.<br /><br />" +
        "Zugang dazu erhältst du nur mit dem VIP Hörspiel-Pass, selbstverständlich ohne zusätzliche Kosten.",
    DDF_REDEMPTION_HEADLINE_3: "SO <span style='color: #e92f23'>EINFACH</span> <span style='color: #02abeb'>GEHT’S</span>",
    DDF_REDEMPTION_INFO_3: "Quick-Guide und FAQs als PDF <span style='color: #02abeb; cursor: pointer;'>hier downloaden.</span>",
    DDF_REDEMPTION_HEADLINE_4: "ZUGANG AUCH MIT<br/>DER <span style='color: #e92f23'>TIGERBOX<br/>TOUCH</span> <span style='color: #02abeb'>MÖGLICH!</span>",
    DDF_REDEMPTION_INFO_4: "Einfach VIP Hörspiel-Pass in den Karteneinschub der tigerbox TOUCH stecken und direkt loshören!",
    DDF_REDEMPTION_HEADLINE_5: "ALLE FOLGEN<br/>IN <span style='color: #e92f23'>EINER</span> <span style='color: #02abeb'>APP</span>",
    DDF_REDEMPTION_INFO_5: "Mit der tigertones-App erhältst du für nur wenige Euro pro Monat Zugang zur tigertones-Audiothek für Kinder mit mehr als 20.000 Hörspielen, Hörbüchern " +
        "und Musik, inkl. aller Folgen von Die&nbsp;drei&nbsp;??? <br /><br />" +
        "Interessiert? Dann erfahre hier mehr: <a href='http://tiger.media/pages/tigertones' target='_blank'>tiger.media/pages/tigertones</a>",
    DDF_REDEMPTION_UNKNOWN_ERROR: "Ein Fehler ist aufgetreten. Bitte versuche es gleich noch einmal oder wende dich an unseren Support.",
    DDF_REDEMPTION_CODE_ERROR_1: "Dieser Zugangscode ist leider ungültig.",
    DDF_REDEMPTION_CODE_ERROR_2: "Dieser VIP-Hörspiel-Pass ist aufgrund von zu häufiger Falscheingaben für {0} Minuten gesperrt.",
    DDF_REDEMPTION_CODE_ERROR_3: "PIN zu häufig falsch eingegeben. VIP-Hörspiel-Pass wird für 30 Minuten gesperrt.",
    DDF_REDEMPTION_PIN_ERROR_1: "Der PIN ist leider nicht korrekt. {0} Versuche verbleiben.",

    DDF_AUTHENTICATE_TITLE: "JETZT<br/>ANMELDEN!",
    DDF_AUTHENTICATE_INFO: "Melde dich hier mit deinen bestehenden " +
            "tigertones-Zugangsdaten an," +
            "um anschließend den Zugangscode und PIN " +
            "deines VIP-Hörspiel-Passes mit deiner " +
            "persönlichen Audiothek zu verknüpfen. " +
            "Für die Nutzung des VIP-Hörspiel-Passes " +
            "ist die aktuelleste App-Version zwingend " +
            "notwendig.",
    DDF_AUTHENTICATE_INFO_NEW_ACCOUNT: "Deine Registrierung ist abgeschlossen. Melde dich jetzt an, um deinen Gutschein der <span style='font-weight: bold'>Die drei ?<span style='color: #e92f23'>?</span>" +
        "<span style='color: #02abeb'>?</span></span> einzulösen.",
    DDF_AUTHENTICATE_EMAIL: "E-Mail-Adresse:",
    DDF_AUTHENTICATE_PASSWORD: "Passwort:",
    DDF_AUTHENTICATE_EMAIL_HINT: "Bitte gib deine E-Mail-Adresse ein",
    DDF_AUTHENTICATE_PASSWORD_HINT: "Bitte gib dein Passwort ein",
    DDF_AUTHENTICATE_BUTTON: "Anmelden",
    DDF_SWITCH_TO_REGISTER_INFO: "Noch kein tigertones-Konto? Dann kannst du dich jetzt hier registrieren!",
    DDF_SWITCH_TO_REGISTER_BUTTON: "Jetzt registrieren",
    DDF_AUTHENTICATE_EMAIL_ERROR: "Es existiert kein tigertones-Konto zu dieser E-Mail-Adresse",
    DDF_AUTHENTICATE_ERROR: "Die Anmeldedaten sind nicht korrekt",

    DDF_REGISTER_TITLE: "JETZT<br/>REGISTRIEREN!",
    DDF_REGISTER_INFO: "Registriere dich hier und erstelle kostenlos deinen persönlichen tigertones-Konto, um Zugang zu den Live-Mitschnitten der <span style='font-weight: bold'>Die&nbsp;drei&nbsp;??? Live Tour 2022</span> zu erhalten oder melde dich einfach mit deinen bestehenden tigertones-Zugangsdaten an.",
    DDF_REGISTER_EMAIL: "E-Mail-Adresse:",
    DDF_REGISTER_EMAIL_HINT: "Bitte gib deine E-Mail-Adresse ein",
    DDF_REGISTER_PASSWORD: "Passwort:",
    DDF_REGISTER_PASSWORD_HINT: "Mindestens 8 Zeichen",
    DDF_REGISTER_PASSWORD_CONFIRMATION: "Passwort bestätigen:",
    DDF_REGISTER_PASSWORD_CONFIRMATION_HINT: "Bitte bestätige dein Passwort",
    DDF_REGISTER_BUTTON: "Registrieren",
    DDF_SWITCH_TO_LOGIN_INFO: "Du hast bereits einen tigertones-Konto? Dann melde dich jetzt an!",
    DDF_SWITCH_TO_LOGIN_BUTTON: "Jetzt anmelden",
    DDF_REGISTER_INVALID_EMAIL: "Bitte gib eine gültige E-Mail-Adresse ein",
    DDF_REGISTER_PASSWORD_DONT_MATCH: "Die Passwörter stimmen nicht überein",
    DDF_REGISTER_PASSWORD_TOO_SHORT: "Das Passwort muss mindestens 8 Zeichen haben",
    DDF_REGISTER_EMAIL_ERROR: "Es existiert bereits ein tigertones-Konto mit dieser E-Mail-Adresse",

    DDF_CHANGE_ACCOUNT_BUTTON: "Neu anmelden",
    DDF_REDEEM_BUTTON: "Jetzt einlösen",
    DDF_PREVIEW_HEADLINE: "VIP HÖRSPIEL-<br/>PASS EINLÖSEN!",
    DDF_PREVIEW_INFO: "Klicke auf die Schaltfläche „Jetzt einlösen“, um Zugangscode und PIN deines VIP Hörspiel-Passes mit deinem tigertones-Konto zu verknüpfen.",
    DDF_SWITCH_ACCOUNT_INFO: "Du bist als <span style='font-weight: bold; color: #02abeb'>{0}</span> angemeldet. <br />Nicht dein tigertones-Konto? Dann melde dich " +
        "jetzt neu an!",

    DDF_SUCCESS_TITLE: "Juhuu, das hat geklappt !<span style='color: #e92f23'>!</span>" +
        "<span style='color: #02abeb'>!</span>",
    DDF_SUCCESS_HEADLINE: "TIGERTONES-APP<br/>ÖFFNEN!",
    DDF_SUCCESS_OPEN_PLAYLIST: "Jetzt reinhören",
    DDF_SUCCESS_INSTALL_APP_HINT: "Du hast die tigertones-App noch nicht? Kein Problem! " +
        "Klicke einfach auf einen der folgenden Links, um tigertones jetzt herunterzuladen.",
    DDF_SUCCESS_INSTALL_APP_HINT_2: "Du nutzt bereits die aktuellste Version der tigertones-App? Dann klicke auf „Jetzt reinhören“, " +
        "um die Live-Mitschnitte der Die drei ??? Live Tour 2022 abzurufen.",
    DDF_SUCCESS_INSTALL_APP_HINT_3: "Alle Live-Mitschnitte findest du in der tigertones-App unter der Rubrik „Käufe & Hörspiel-Pässe“.",

    DDF_REDIRECT_INFO: "Höre jetzt direkt in die Live-Mitschnitte der Die drei drei ?? Live Tour 2022 rein",
    //TICKET REDEMPTION
    TICKET_REDEMPTION_ERROR_TITLE: "Oh, was ist denn hier passiert ?",
    TICKET_REDEMPTION_ERROR_INFO_1: "Wir konnten deine Ticket-Informationen leider nicht laden.",
    TICKET_REDEMPTION_ERROR_INFO_2: "Bitte versuche es erneut, indem du deine Ticket-Nummer und den PIN manuell eingibst oder wende dich an " +
        "<a href='https://helpcenter.tiger.media/hc/de'>unseren Support</a>.",
    TICKET_REDEMPTION_ERROR_BUTTON: "Ticketinformationen eingeben",
    TICKET_REDEMPTION_REDEMPTION_BROWSER_TAB: "Ticket einlösen :: tiger.media",
    TICKET_REDEMPTION_VALIDATION_TITLE: "Hier kannst du dein tigertones-Ticket einlösen!",
    TICKET_REDEMPTION_REDEMPTION_INFO: "Löse hier dein tigertones-Ticket ein und erhalte so Zugriff auf Zehntausende Hörspiele, Hörbücher und jede Menge Musik in tigertones!",
    TICKET_REDEMPTION_REDEMPTION_CODE_PLACEHOLDER: "Bitte gib den Zugangscode ein",
    TICKET_REDEMPTION_REDEMPTION_PIN_PLACEHOLDER: "Bitte gib die PIN ein",
    TICKET_REDEMPTION_REDEMPTION_UNKNOWN_ERROR: "Ein Fehler ist aufgetreten. Bitte versuche es gleich noch einmal oder wende dich an <a href='https://helpcenter.tiger.media/hc/de' target='_blank'>unseren Support</a>.",
    TICKET_REDEMPTION_REDEMPTION_CODE_ERROR_1: "Dieser Zugangscode ist leider ungültig.",
    TICKET_REDEMPTION_REDEMPTION_CODE_ERROR_2: "Dieses Ticket ist aufgrund von zu häufiger Falscheingaben für {0} Minuten gesperrt.",
    TICKET_REDEMPTION_REDEMPTION_CODE_ERROR_3: "PIN zu häufig falsch eingegeben. Ticket wird für 30 Minuten gesperrt.",
    TICKET_REDEMPTION_REDEMPTION_CODE_ERROR_4: "Dieses Ticket wurde bereits eingelöst.",
    TICKET_REDEMPTION_REDEMPTION_CODE_ERROR_6: "Du verfügst bereits über einen tigerbooks-Zugang. Während dieser Laufzeit ist eine tigertones-Ticketeinlösung leider nicht möglich.",
    TICKET_REDEMPTION_REDEMPTION_PIN_ERROR_1: "Der PIN ist leider nicht korrekt. {0} Versuche verbleiben.",
    TICKET_REDEMPTION_REDEMPTION_ACCOUNT_ERROR_1: "Du verfügst bereits über ein laufendes Abo. Bitte melde dich zur Einlösung des Tickets mit einem anderen Benutzer an.",
    TICKET_REDEMPTION_REDEMPTION_ACCOUNT_ERROR_2: "Du verfügst bereits über ein laufendes Abo (verlängert sich am {0}). Bitte melde dich zur Einlösung des Tickets mit einem anderen Benutzer an.",
    TICKET_REDEMPTION_REDEMPTION_ACCOUNT_ERROR_3: "Dein Abo läuft am {0} aus. Bitte versuche es dann erneut oder melde dich zur Einlösung des Tickets mit einem anderen Benutzer an.",

    TICKET_REDEMPTION_AUTHENTICATE_TITLE: "Jetzt anmelden!",
    TICKET_REDEMPTION_AUTHENTICATE_INFO: "Du hast das <strong>{0}</strong> ausgewählt.<br />Melde dich jetzt mit dem Benutzer ein, auf den das Ticket eingelöst werden soll.",
    TICKET_REDEMPTION_AUTHENTICATE_EMAIL: "E-Mail-Adresse:",
    TICKET_REDEMPTION_AUTHENTICATE_PASSWORD: "Passwort:",
    TICKET_REDEMPTION_AUTHENTICATE_EMAIL_HINT: "Bitte gib deine E-Mail ein",
    TICKET_REDEMPTION_AUTHENTICATE_PASSWORD_HINT: "Bitte gib dein Passwort ein",
    TICKET_REDEMPTION_AUTHENTICATE_BUTTON: "Anmelden",
    TICKET_REDEMPTION_SWITCH_TO_REGISTER_INFO: "Noch kein tigertones-Konto? Dann kannst du dich jetzt hier registrieren!",
    TICKET_REDEMPTION_SWITCH_TO_REGISTER_BUTTON: "Jetzt registrieren",
    TICKET_REDEMPTION_AUTHENTICATE_EMAIL_ERROR: "Es existiert kein tigertones-Konto zu dieser E-Mail-Adresse",
    TICKET_REDEMPTION_AUTHENTICATE_ERROR: "Die Anmeldedaten sind nicht korrekt",

    TICKET_REDEMPTION_REGISTER_TITLE: "Jetzt registrieren!",
    TICKET_REDEMPTION_REGISTER_INFO: "Du hast das <strong>{0}</strong> ausgewählt.<br />Registriere dich jetzt, um das ganze tigerversum zu entdecken und dein Ticket einzulösen.",
    TICKET_REDEMPTION_REGISTER_EMAIL: "E-Mail-Adresse:",
    TICKET_REDEMPTION_REGISTER_EMAIL_HINT: "Bitte gib deine E-Mail ein",
    TICKET_REDEMPTION_REGISTER_PASSWORD: "Passwort:",
    TICKET_REDEMPTION_REGISTER_PASSWORD_HINT: "Mindestens 8 Zeichen",
    TICKET_REDEMPTION_REGISTER_PASSWORD_CONFIRMATION: "Password bestätigen:",
    TICKET_REDEMPTION_REGISTER_PASSWORD_CONFIRMATION_HINT: "Bitte bestätige dein Passwort",
    TICKET_REDEMPTION_REGISTER_BUTTON: "Registrieren",
    TICKET_REDEMPTION_SWITCH_TO_LOGIN_INFO: "Du hast bereits ein tigertones-Konto? Dann melde dich jetzt an!",
    TICKET_REDEMPTION_SWITCH_TO_LOGIN_BUTTON: "Anmelden",
    TICKET_REDEMPTION_REGISTER_INVALID_EMAIL: "Bitte gib eine gültige E-Mail-Adresse ein",
    TICKET_REDEMPTION_REGISTER_PASSWORD_DONT_MATCH: "Die Passwörter stimmen nicht überein",
    TICKET_REDEMPTION_REGISTER_PASSWORD_TOO_SHORT: "Das Passwort muss mindestens 8 Zeichen haben",
    TICKET_REDEMPTION_REGISTER_EMAIL_ERROR: "Es existiert bereits ein tigertones-Konto mit dieser E-Mail-Adresse",

    TICKET_REDEMPTION_PREVIEW_TITLE: "Dein tigertones-Ticket steht bereit!",
    TICKET_REDEMPTION_CHANGE_ACCOUNT_BUTTON: "Neu anmelden",
    TICKET_REDEMPTION_REDEEM_BUTTON: "Jetzt einlösen",
    TICKET_REDEMPTION_PREVIEW_INFO_1: "Du hast das <strong>{0}</strong> ausgewählt. Durch das Einlösen verlängert sich deine Laufzeit bis zum <strong>{1}</strong>.",
    TICKET_REDEMPTION_PREVIEW_DURATION: "Laufzeit: ",
    TICKET_REDEMPTION_PREVIEW_ENDDATE: "Endet am: ",
    TICKET_REDEMPTION_PREVIEW_INFO_2: "Durch Klicken auf „Einlösen“ stimme ich den <a href=https://tiger.media/policies/terms-of-service' target='_blank'>AGB</a> zu.",
    TICKET_REDEMPTION_SWITCH_ACCOUNT_INFO: "Du bist als <span style='font-weight: bold; color: #02abeb'>{0}</span> angemeldet. <br />Nicht dein tigertones-Konto? Dann melde dich " +
        "jetzt neu an!",

    TICKET_REDEMPTION_SUCCESS_TITLE: "Jippie!",
    TICKET_REDEMPTION_SUCCESS_INFO: "Jetzt kannst du loslegen und das ganze Universum von tigermedia mit deinem tigertones-Ticket entdecken. Spannende Abenteuer und Geschichten warten schon darauf, von dir entdeckt zu werden!",
    TICKET_REDEMPTION_SUCCESS_OPEN_PLAYLIST: "Jetzt reinhören",
    TICKET_REDEMPTION_SUCCESS_INSTALL_APP_HINT: "Du hast die <span style='font-weight: bold'>tigertones-App</span> noch nicht installiert? Kein Problem! " +
        "Klicke einfach auf einen der folgenden Links, um tigertones jetzt herunterzuladen.",
    TICKET_REDEMPTION_SUCCESS_BUTTON: "Zurück zur Übersicht",

    TICKET_REDEMPTION_ERROR_1: "Das Ticket wurde bereits eingelöst. Wenn du der Meinung bist, dass das nicht korrekt ist, wende dich bitte an <a href='https://helpcenter.tiger.media/hc/de' target='_blank'>unseren Support</a>.",
    TICKET_REDEMPTION_ERROR_2: "Zu deinem Benutzer wurde bereits ein aktives Abo gefunden. Während dieser Laufzeit kann kein Ticket eingelöst werden.",

    //PURCHASES
    PURCHASE_TARIF_ERROR_NO_AVAILABLE_TARIF_FOUND: "Leider steht aktuell kein Tarif zur Verfügung. Bitte versuche es zu einem späteren Zeitpunkt noch einmal.",
    PURCHASE_TARIF_ERROR_ACTIVE_IAP: "Du hast aktuell ein aktives tigertones-In-App-Abo bei Apple oder Google. Während dieser Laufzeit kann kein weiterer Tarif gebucht werden.",
    PURCHASE_TARIF_UNKNOWN_ERROR: "Beim Laden der Daten ist ein Fehler aufgetreten. Bitte versuche es gleich erneut oder wende dich an unseren Support.",
    PURCHASE_TARIF_WITH_SELECTED_TARIF_INFO: "Du hast den Tarif <strong>{0}</strong> ausgewählt. Bitte melde dich jetzt an, um fortzufahren.",
    PURCHASE_TARIF_WITHOUR_SELECTED_TARIF_INFO: "Bitte melde dich jetzt an, um fortzufahren.",
    PURCHASE_TARIF_SELECT_TARIF: "Bitte wähle deinen passenden Tarif:",

    PURCHASE_TARIF_CURRENT_TARIF: "Aktueller Tarif:",
    PURCHASE_TARIF_NEXT_TARIF: "Dein neuer Tarif ab <strong>{0}</strong>:",
    PURCHASE_TARIF_TM_SUBSCRIPTION: "tigertones-Website-Abo",
    PURCHASE_TARIF_TM_TICKET: "tigertones-Ticket",

    PURCHASE_SUBSCRIPTION_ERROR_SUBSCRIPTION_ACTIVE: "Du hast bereits einen aktiven tigertones-Zugang.<br /><br />Während dieser Laufzeit kann kein weiteres Abo abgeschlossen werden.",
    PURCHASE_BUNDLE_BROWSER_TAB: "tiger Kombi-Paket kaufen :: tiger.media",
    PURCHASE_SUBSCRIPTION_BROWSER_TAB: "Dein Abo :: tiger.media",
    PURCHASE_BUNDLE_SUCCESS_BROWSER_TAB: "Dein tiger Kombi-Paket steht bereit :: tiger.media",
    PURCHASE_TICKET_BROWSER_TAB: "Dein Ticket :: tiger.media",
    PURCHASE_STATUS_STEP_0: "Farbe wählen",
    PURCHASE_STATUS_STEP_1: "Registrieren<br/>Anmelden",
    PURCHASE_STATUS_STEP_2: "Daten angeben",
    PURCHASE_STATUS_STEP_3: "Bestätigen",
    PURCHASE_STATUS_STEP_4: "Fertig",
    PURCHASE_AUTHENTICATE_TITLE: "Jetzt anmelden!",
    COLOR_CHOSER_INFO: "Bitte wähle die gewünschte Farbe",
    COLOR_CHOSER_SELECTED_COLOR: "({0})",
    PURCHASE_TICKET_AUTHENTICATE_INFO: "Du hast das <strong>digitale Ticket {0}</strong> ausgewählt.<br />Bitte melde dich an, um fortzufahren.",
    PURCHASE_TICKET_REGISTER_INFO: "Du hast das <strong>tigertones-Abo mit Mindestlaufzeit {0}</strong> ausgewählt.<br />Erstelle jetzt einen tigertones-Konto, um fortzufahren.",
    PURCHASE_SUBSCRIPTION_AUTHENTICATE_INFO: "Du hast das <strong>tigertones-Abo mit Mindestlaufzeit {0}</strong> ausgewählt.<br />Bitte melde dich an, um fortzufahren.",
    PURCHASE_SUBSCRIPTION_REGISTER_INFO: "Du hast <strong>{0} tigertones-Abo</strong> ausgewählt.<br />Erstelle jetzt einen tigertones-Konto, um fortzufahren.",

    PURCHASE_PAYMENT_METHOD_INFO: "Bitte wähle die Bezahlmethode aus:",
    PURCHASE_PAYMENT_METHOD_CC: "Kreditkarte",
    PURCHASE_TICKET_PAYMENT_METHOD_PAYPAL: "PayPal",
    PURCHASE_SUBSCRIPTION_PAYMENT_METHOD_DEBIT: "Lastschrift",
    PURCHASE_SUBSCRIPTION_DEBIT_OWNER: "Kontoinhaber",
    PURCHASE_SUBSCRIPTION_DEBIT_IBAN: "IBAN",
    PURCHASE_SUBSCRIPTION_DEBIT_BIC: "BIC",
    PURCHASE_SUBSCRIPTION_CC_NAME: "Name auf der Karte",
    PURCHASE_SUBSCRIPTION_CC_NUMBER: "Kreditkartennummer",
    PURCHASE_SUBSCRIPTION_CC_MONTH: "Monat",
    PURCHASE_SUBSCRIPTION_CC_YEAR: "Jahr",
    PURCHASE_SUBSCRIPTION_CC_VERIFICATION: "Kartenprüfnummer",
    PURCHASE_SUBSCRIPTION_DEBIT_AGB_TITLE: "Ich stimme dem SEPA Lastschrift-Mandat zu",
    PURCHASE_SUBSCRIPTION_DEBIT_AGB_INFO: "Ich ermächtige/ Wir ermächtigen (A) Tiger Media Deutschland GmbH Zahlungen von meinem/ unserem Konto mittels Lastschrift " +
        "einzuziehen. Zugleich (B) weise ich mein/ weisen wir unser Kreditinstitut an, die von Tiger Media Deutschland GmbH auf mein/ unser Konto gezogenen Lastschriften einzulösen. " +
        "Hinweis: Ich kann/ Wir können innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem/ unserem Kreditinstitut vereinbarten Bedingungen.",
    PURCHASE_PROCEED_BUTTON: "Weiter",
    PURCHASE_FORM_FIELD_REQUIRED: "Bitte fülle dieses Feld aus",
    PURCHASE_FORM_CC_NUMBER_INVALID: "Bitte gib eine gültige Kartennummer an",
    PURCHASE_FORM_CC_EXPIRRY_MONTH_INVALID: "Bitte gib einen gültigen Monat an",
    PURCHASE_FORM_CC_EXPIRY_YEAR_INVALID: "Bitte gib ein gültiges Jahr an",
    PURCHASE_FORM_CC_VERIFICATION_NO_INVALID: "Bitte gib eine gültige Kartenprüfnummer an",
    PURCHASE_FORM_CC_CARD_EXPIRED: "Die Gültigkeit der Karte ist abgelaufen",
    PURCHASE_FORM_DEBIT_IBAN_INVALID: "Bitte gib eine gültige IBAN an",
    PURCHASE_FORM_DEBIT_BIC_INVALID: "Bitte gib eine gültige BIC an",

    PURCHASE_CONTACT_INFO: "Bitte gib hier deine Adresse ein. Diese benötigen wir nur zur Erstellung der Rechnung.",
    PURCHASE_CONTACT_FIRSTNAME: "Vorname",
    PURCHASE_CONTACT_LASTNAME: "Nachname",
    PURCHASE_CONTACT_EMAIL: "E-Mail-Adresse",
    PURCHASE_CONTACT_STREET: "Straße",
    PURCHASE_CONTACT_HOUSE_NO: "Nr.",
    PURCHASE_CONTACT_POSTAL_CODE: "Postleitzahl",
    PURCHASE_CONTACT_CITY: "Wohnort",
    PURCHASE_CONTACT_COUNTRY: "Land",
    PURCHASE_COUNTRY_DE: "Deutschland",
    PURCHASE_COUNTRY_CH: "Schweiz",
    PURCHASE_COUNTRY_AT: "Österreich",
    PURCHASE_CONTACT_ERROR: "Bitte fülle dieses Feld aus",
    PURCHASE_EDIT_CUSTOMER_TITLE: "Rechnungsadresse bearbeiten",

    PURCHASE_BUNDLE_AUTHENTICATE_INFO: "Bitte melde dich an, um fortzufahren.",
    PURCHASE_BUNDLE_REGISTER_INFO: "Erstelle jetzt einen tigertones-Konto, um fortzufahren.",
    PURCHASE_BUNDLE_CONTACT_INFO: "Bitte gib hier deine Versand- und Rechnungs-Adresse ein.",
    PURCHASE_CONFIRMATION_INFO: "Gleich geschafft! Hier noch einmal deine Bestellung im Überblick:",
    PURCHASE_CONFIRMATION_BUNDLE_NAME: "Dein tiger-Paket",
    PURCHASE_SUBSCRIPTION_BUNDLE_TIGERTONES_NAME: "Audioflatrate tigertones (Streaming von Hörbüchern und -spielen sowie von Kindermusik) für {0}",
    PURCHASE_CONFIRMATION_BUNDLE_TIGERBOX_NAME: "inkl. tigerbox TOUCH (Farbe: {0})",
    PURCHASE_CONFIRMATION_BUNDLE_SETUP_FEE: "Einmalig",
    PURCHASE_CONFIRMATION_BUNDLE_PER_MONTH: "Monatlich",
    PURCHASE_CONFIRMATION_DISCOUNTED_PER_MONTH: "Die ersten {0} Monate je",
    PURCHASE_CONFIRMATION_DISCOUNTED_PER_MONTH_WITH_TRIAL: "Monate {0} bis {1} je",
    PURCHASE_CONFIRMATION_DISCOUNTED: "Danach ",
    PURCHASE_CONFIRMATION_BUNDLE_AGB: "Durch Klicken auf „Jetzt kaufen“ stimme ich den <a href='https://tiger.media/policies/terms-of-service' target='_blank'>AGB</a> zu.",

    BOX_COLOR_RED: "Rot",
    BOX_COLOR_GREY: "Grau",
    BOX_COLOR_VIOLET: "Lila",
    BOX_COLOR_BLACK: "Schwarz",
    BOX_COLOR_BLUE: "Blau",
    BOX_COLOR_GREEN: "Grün",
    PURCHASE_SUBSCRIPTION_CONFIRMATION_TIGERBOX_COLOR: "Farbe: ",
    PURCHASE_CONFIRMATION_PRODUCT_DATA: "tigertones-Abo",
    PURCHASE_TICKET_CONFIRMATION_PRODUCT_NAME: "Digitales Tigerticket für {0} tigertones (Streaming von Hörbüchern und -spielen sowie von Kindermusik)",
    PURCHASE_TICKET_CONFIRMATION_PRODUCT_ACCESS: " Du erhältst deinen Zugangscode per E-Mail",
    PURCHASE_TICKET_CONFIRMATION_PRODUCT_CANCELLATION: "Keine Kündigung nötig",
    PURCHASE_SUBSCRIPTION_CONFIRMATION_PRODUCT_NAME: "tigertones (Streaming von Hörbüchern und -spielen sowie von Kindermusik)",
    PURCHASE_SUBSCRIPTION_CONFIRMATION_PRODUCT_PRICE: "{0} pro {1}",
    PURCHASE_SUBSCRIPTION_CONFIRMATION_PRODUCT_CANCELLATION: "Kündigung jederzeit mit Ablauf des Vertragsmonats",
    PURCHASE_SUBSCRIPTION_CONFIRMATION_PRODUCT_CANCELLATION_BUNDLE: "Kündigung zum Ablauf der Mindestvertragslaufzeit von {0}n, danach jederzeit mit Ablauf des Vertragsmonats",
    PURCHASE_SUBSCRIPTION_CONFIRMATION_PRODUCT_TRIAL: "Die erste Abbuchung erfolgt nach deiner Probephase am {0}",
    PURCHASE_SUBSCRIPTION_FIRST_PAYMENT: "Erste Abbuchung am {0}",
    PURCHASE_CONFIRMATION_NETTO: "Summe Netto",
    PURCHASE_CONFIRMATION_MWST: "MwSt.",
    PURCHASE_CONFIRMATION_BRUTTO: "Summe Brutto",
    PURCHASE_CONFIRMATION_PERSONAL_DATA: "Deine Daten",
    PURCHASE_CONFIRMATION_ACCOUNT: "tigertones-Konto",
    PURCHASE_CONFIRMATION_INVOICE: "Rechnungsdaten",
    PURCHASE_CONFIRMATION_EDIT: "Bearbeiten",
    PURCHASE_CONFIRMATION_CHANGE: "Wechseln",
    PURCHASE_CONFIRMATION_PAYMENT_METHOD: "Zahlungsart",
    PURCHASE_CONFIRMATION_AGB: "Ich stimme zu, dass tigermedia vor Ablauf der Widerrufsfrist mit der Ausführung des Vertrags beginnt. Mir ist klar, dass damit mein Widerrufsrecht erlischt.<br /><br />" +
        "Durch Klicken auf „Jetzt kaufen“ stimme ich den <a href='https://tiger.media/policies/terms-of-service' target='_blank'>AGB</a> zu.",
    PURCHASE_CONFIRMATION_SUBMIT_BUTTON: "Jetzt kaufen",
    PURCHASE_CONFIRMATION_ACTIVE_SUBSCRIPTION_ERROR: "Zu der E-Mail-Adresse <strong>{0}</strong> existiert bereits über einen aktiven Zugang.<br /><br />Während dieser Laufzeit kann kein weiteres Abo abgeschlossen werden.",
    PURCHASE_CONFIRMATION_UNKNOWN_ERROR: "Ein unbekannter Fehler ist aufgeteten, der Kauf konnte leider nicht abgeschlossen werden.<br /><br />" +
        "Bitte versuche es gleich noch einmal oder wende dich an unseren <a href='https://helpcenter.tiger.media/hc/de' target='_blank'>Support</a>.",
    PURCHASE_CONFIRMATION_PRODUCT_ERROR: "Ein unbekannter Fehler ist aufgeteten, der Kauf konnte leider nicht abgeschlossen werden.<br /><br />" +
        "Bitte versuche es gleich noch einmal oder wende dich an unseren <a href='https://helpcenter.tiger.media/hc/de' target='_blank'>Support</a>.",
    PURCHASE_CONFIRMATION_PAYMENT_ERROR: "Ein unbekannter Fehler ist aufgeteten, der Kauf konnte leider nicht abgeschlossen werden.<br /><br />" +
        "Bitte versuche es gleich noch einmal oder wende dich an unseren <a href='https://helpcenter.tiger.media/hc/de' target='_blank'>Support</a>.",
    PURCHASE_CONFIRMATION_CONTACT_ERORR: "Ein unbekannter Fehler ist aufgeteten, der Kauf konnte leider nicht abgeschlossen werden.<br /><br />" +
        "Bitte versuche es gleich noch einmal oder wende dich an unseren <a href='https://helpcenter.tiger.media/hc/de' target='_blank'>Support</a>.",

    PURCHASE_CONFIRMATION_VALIDATION_ERROR: "Bei der Bestellung ist leider ein Fehler aufgetreten. Bitte versuche es gleich noch einmal oder wende dich " +
        "an unseren <a href='https://helpcenter.tiger.media/hc/de' target='_blank'>Support</a> und teile uns den folgenden Fehlercode mit: <strong>Fehlercode {0}</strong>",

    PURCHASE_REAUTHENTICATE_HINT: "Wenn du dich mit einem anderen Account anmeldest, werden deine bisher eingegebenen Daten aus Sicherheitsgründen gelöscht.<br /><br />" +
        "Möchtest du wirklich fortfahren?",
    PURCHASE_REAUTHENTICATE_TITLE: "Neu anmelden",
    PURCHASE_REAUTHENTICATE_BUTTON: "Neu anmelden",

    PURCHASE_SUCCESS_TITLE: "Jippie!",
    PURCHASE_TICKET_SUCCESS_INFO: "In Kürze erhältst du deinen tigertones-Ticket Zugangscode per E-Mail an <strong>{0}</strong>. Diesen kannst du jederzeit einlösen. " +
        "Spannende Abenteuer und Geschichten warten schon darauf, von dir entdeckt zu werden!",
    PURCHASE_SUBSCRIPTION_SUCCESS_INFO: "Jetzt kannst du loslegen und das ganze Universum von tigermedia mit deinem tigertones-Abo entdecken. Spannende Abenteuer und Geschichten warten schon darauf, von dir entdeckt zu werden!",
    PURCHASE_SUCCESS_INFO_2: "Lade die App auf dein Smartphone oder starte deine tigerbox, um direkt loszulegen!!",
    PURCHASE_PAYMENT_ERROR_TITLE: "Bezahlvorgang nicht möglich",
    PURCHASE_PAYMENT_ERROR_MASTERCARD: "Der Kauf per Mastercard ist derzeit aus technischen Gründen leider nicht möglich.",
    PURCHASE_PAYMENT_ERROR_BODY: "Der Bezahlvorgang konnte leider nicht abgeschlossen werden. Bitte versuche es gleich noch einmal oder wende dich an unseren Support.",

    // TIGERTICKETS
    TIGERTICKETS_LABEL_PURCHASED: "Gekauft am",
    TIGERTICKETS_LABEL_EXPIRES: "Gültig bis zum",
    TIGERTICKETS_LABEL_DAYS_LEFT: "Tage übrig",
    TIGERTICKETS_LABEL_1_DAY_LEFT: "1 Tag übrig",

    TIGERTICKETS_SORY_BY: "Sortieren nach",
    TIGERTICKETS_SORT_EXPIRY_DATE: "Ablaufdatum",
    TIGERTICKETS_SORT_PURCHASE_DATE: "Kaufdatum",
    TIGERTICKETS_SHOW_ACTIVE: "Nur aktives tigertones-Ticket anzeigen",
    TIGERTICKETS_SHOW_ALL: "Zeige alle tigertones-Tickets",

    TIGERTICKETS_NOTHING_FOUND: "Keine tigertones-Tickets gefunden",
    TIGERTICKETS_ERROR_UNKNOWN_TICKET: "Unbekanntes tigertones-Ticket",

    PURCHASE_ERROR_TITLE: "Hoppla!",
    PURCHASE_ERROR_INFO: "Bei dem Kaufprozess ist leider ein Fehler aufgetreten. Bitte versuche es gleich erneut oder wende dich dich an " +
        "<a href='https://helpcenter.tiger.media/hc/de' target='blank'>unseren Support</a>.",
    PURCHASE_ERROR_INFO_WITH_CODE: "Beim Kaufprozess ist leider ein Fehler aufgetreten. Bitte versuche es gleich erneut oder wende dich dich an " +
        "<a href='https://helpcenter.tiger.media/hc/de' target='blank'>unseren Support</a> und teile uns den folgenden Fehlercode mit: <strong>{0}</strong>",

    // TIGERBOXES
    TIGERBOXES_LABEL_DEVICE_ID: "Geräte-ID",
    TIGERBOXES_LABEL_VERSION: "Software-Version",
    TIGERBOXES_LABEL_LOCAL_IP: "Lokale IP-Adresse",
    TIGERBOXES_LABEL_FIRST_ONBOARDED_DATE: "Zuerst eingerichtet am",

    TIGERBOXES_RELEASE_BUTTON_LABEL: "Aus meinem tigertones-Konto löschen",
    TIGERBOXES_MODAL_RELEASE_TITLE: "Diese tigerbox aus meinem tigertones-Konto löschen",
    TIGERBOXES_MODAL_RELEASE_TEXT: "Bist du sicher, dass du diese tigerbox aus deinem tigertones-Konto löschen möchtest?",
    TIGERBOXES_MODAL_RELEASE_BUTTON_LABEL: "Löschen",
    TIGERBOXES_UNASSIGN_SUCCESS: "Diese tigerbox wurde erfolgreich aus deinem tigertones-Konto gelöscht.",
    TIGERBOXES_UNASSIGN_ERROR: "Ein Fehler beim Löschen dieser tigerbox ist aufgetreten. Fehlermeldung: ",

    TIGERBOXES_NOTHING_FOUND: "Keine Geräte gefunden",

    // WATCHLIST
    WATCHLIST_SELECT_USER: "Bitte ein Profil auswählen",
    WATCHLIST_NO_USER_SELECTED: "Kein Profil ausgewählt",

    WATCHLIST_EMPTY: "Deine Merkliste ist leer",
    WATCHLIST_ORDER_SAVED_SUCCESS: "Neue Reihenfolge in deiner Merkliste wurde gespeichert",
    WATCHLIST_ORDER_SAVED_ERROR: "Ein Fehler beim Speichern der geänderten Reihenfolge der Merkliste ist aufgetreten. Vorherige Reihenfolge wird wieder hergestellt.",

    WATCHLIST_REMOVE_BUTTON_LABEL: "Löschen",
    WATCHLIST_REMOVE_BUTTON_EXTENDED_LABEL: "Entfernen",
    WATCHLIST_ADD_BUTTON_LABEL: "Hinzufügen",
    WATCHLIST_ADD_HEADLINE: "Füge diesen Titel zur Merkliste des ausgewählten Profils hinzu oder entferne ihn von der Merkliste",
    WATCHLIST_MODAL_REMOVE_TITLE: "Diesen Titel aus der Merkliste löschen",
    WATCHLIST_MODAL_REMOVE_TEXT: "Möchtest du diesen Titel wirklich aus der Merkliste löschen?",
    WATCHLIST_MODAL_REMOVE_BUTTON_LABEL: "Löschen",
    WATCHLIST_NO_LOGIN_HINT: "<a href=\"/login?goto=/product/{0}\">Melde dich an,</a> um diesen Titel zu deiner Merkliste hinzuzufügen.",

    WATCHLIST_ADD_SUCCESS: "Dieser Titel wurde erfolgreich zu deiner Merkliste hinzugefügt",
    WATCHLIST_ADD_ERROR: "Ein Fehler beim Hinzufügen dieses Titels zu deiner Merkliste ist aufgetreten. Fehlermeldung: ",

    WATCHLIST_REMOVE_SUCCESS: "Dieser Titel wurde erfolgreich aus deiner Merkliste gelöscht",
    WATCHLIST_REMOVE_ERROR: "Ein Fehler beim Löschen dieses Titels aus deiner Merkliste ist aufgetreten. Fehlermeldung: ",

    // PROFILES
    CHOSE_PROFILE: "Profil auswählen",
    PROFILES_AMOUNT: "{0} von {1} Profilen erstellt",
    PROFILES_DETAILS_ADD_CLUSTER: "Jetzt auswählen",
    PROFILES_DETAILS_GOTO_SEARCH: "Zur Suche",
    PROFILES_DETAILS_TITLE: "Informationen zu deinem Profil",
    PROFILES_DETAILS_BIRTHDAY_LABEL: "Geburtsdatum",
    PROFILES_DETAILS_GENDER_LABEL: "Geschlecht",
    PROFILES_DETAILS_AGE_RANGE_LABEL: "Altersspanne",
    PROFILES_DETAILS_GENDER_MALE: "männlich",
    PROFILES_DETAILS_GENDER_FEMALE: "weiblich",
    PROFILES_DETAILS_GENDER_OTHER: "–",
    PROFILES_DETAILS_SELECT_GENDER: "Bitte wählen",
    PROFILES_DETAILS_AGE_RANGE: "{0} bis {1} Jahre",
    PROFILES_DETAILS_UNDEFINED: "Nicht angegeben",
    PROFILES_DETAILS_DELETE_BUTTON: "Profil löschen",
    PROFILES_DETAILS_SAVE_BUTTON: "Speichern",
    PROFILES_DETAILS_EDIT_AGE_RANGE: "Altersspanne ändern",
    PROFILES_DETAILS_EDIT_AGE_RANGE_INFO: "Bitte gib deine gewünschte Altersspanne an:",
    PROFILES_DETAILS_EDIT_AGE_RANGE_TO: "bis",
    PROFILES_DETAILS_EDIT_AGE_RANGE_YEARS: "Jahre",
    PROFILES_AVATAR_SELECTION_TITLE: "Wähle dein Profilbild:",
    PROFILES_NAME_ERROR: "Bitte gib einen Namen für dein Profil an",
    PROFILES_DELETE_BIRTHDAY: "Geburtstag löschen",
    PROFILES_NEW: "Profil hinzufügen",
    PROFILES_HEROS_TITLE: "Heldenauswahl von {0}",
    PROFILES_TOPICS_TITLE: "Weltenauswahl von {0}",
    PROFILE_PREFERENCES_SHOW_PRODUCTS: "Zeige Titel",
    PROFILE_PREFERENCES_SEARCH: "Suche …",
    PROFILE_CLUSTER_SEARCH: "Filter nach Helden-Namen …",
    PROFILE_CLUSTER_ADD: "Zur Auswahl hinzufügen",
    PROFILE_CLUSTER_REMOVE: "Aus Auswahl entfernen",
    PROFILE_PREFERENCES_AMOUNT_HEROS: "{0} von {1} Helden ausgewählt",
    PROFILE_PREFERENCES_AMOUNT_TOPICS: "{0} von {1} Welten ausgewählt",
    PROFILE_PREFERENCES_SHOW_ALL_HEROS_BUTTON: "Alle Helden anzeigen",
    PROFILE_PREFERENCES_SHOW_SELECTED_ONLY_HEROS_BUTTON: "Nur meine Helden anzeigen",
    PROFILE_PREFERENCES_SHOW_ALL_TOPICS_BUTTON: "Alle Welten anzeigen",
    PROFILE_PREFERENCES_SHOW_SELECTED_ONLY_TOPICS_BUTTON: "Nur meine Welten anzeigen",
    PROFILE_PREFERENCES_NO_HEROS: "Keinen Helden gefunden",
    PROFILE_PREFERENCES_NO_TOPICS: "Keine Welten gefunden",
    PROFILE_PREFERENCES_NO_HEROS_FOR_QUERY: "Keinen Helden für {0} gefunden",
    PROFILE_PREFERENCES_NO_TOPICS_FOR_QUERY: "Keine Welten für {0} gefunden",

    //CLUSTERS
    CLUSTER_PRODUCT_OPEN_IN_TIGERTONES: "In tigertones öffnen",
    CLUSTER_PRODUCT_MORE_DETAILS: "Mehr Informationen",
    CLUSTER_PRODUCT_AGE_MIN: "ab {0} Jahren",
    CLUSTER_PRODUCT_AGE_MAX: "bis {0} Jahren",

    PROFILES_DELETION_WARNING_TITLE: "Profil löschen",
    PROFILES_DELETION_WARNING_BODY: "Möchtest du dein Profil <strong>{0}</strong> wirklich löschen?<br /><br />" +
        "Bitte beachte, dass dabei auch die Merkliste dieses Profiles gelöscht wird.<br /><br />Dies kann nicht rückgängig gemacht werden.",
    PROFILES_DELETION_BUTTON: "Löschen",

    PROFILES_AGE_RANGE_POSITIVE_ERROR: "Das Alter darf nicht negativ sein",
    PROFILES_AGE_RANGE_POSITIVE_AGERANGE_ERROR: "Der Abstand muss mindestens 2 Jahre betragen",
    PROFILES_AGE_RANGE_MIN_ERROR: "Das Mindestalter darf nicht kleiner als 0 Jahre oder größer als 8 Jahre sein",
    PROFILES_AGE_RANGE_MAX_ERROR: "Das Maximalalter darf nicht kleiner als 2 Jahre oder größer als 14 Jahre sein",

    //TIGERBOXES
    DEVICES_NOTHING_FOUND: "Keine tigerboxen gefunden",
    DEVICES_LABEL: "tigerboxen",
    DEVICES_DETAILS_NAME_ERROR: "Bitte gib einen Namen für deine tigerbox an",
    DEVICES_DETAILS_LABEL: "Informationen zu deiner {0}",
    DEVICES_DETAILS_DEVICE_IDENTIFIER_LABEL: "Geräte-ID:",
    DEVICES_DETAILS_DEVICE_VERSION_LABEL: "Software-Version:",
    DEVICES_DETAILS_DEVICE_LOCAL_IP_ADDRESS_LABEL: "Lokale IP-Adresse:",
    DEVICES_DETAILS_DEVICE_FIRST_ONBOARDED_AT_LABEL: "Zuerst eingerichtet am:",
    DEVICES_REMOVE_FROM_ACCOUNT_BUTTON: "Aus meinem tigertones-Konto löschen",
    DEVICES_DETAILS_TYPE_NAME_TIGERBOX_TOUCH: "tigerbox",
    DEVICES_REMOVE_WARNING_TITLE: "tigerbox aus tigertones-Konto löschen",
    DEVICES_REMOVE_WARNING_BODY: "Möchtest du die tigerbox <strong>{0}</strong> wirklich aus deinem tigertones-Konto entfernen?<br /><br />" +
        "Wenn du fortfährst, wird die Box zurückgesetzt. Anschließend muss sie neu eingerichtet und einem Profil zugeordnet werden.",
    DEVICES_REMOVE_WARNING_BUTTON: "Entfernen",

    // WILDCARDS
    WILDCARDS_ADD: "Hinzufügen",
    WILDCARDS_NOTHING_FOUND: "Keine wildcards gefunden",

    WILDCARDS_ASSIGN_INPUT_CODE: "Wildcard-Code",
    WILDCARDS_ASSIGN_ADD_BUTTON: "wildcard hinzufügen",
    WILDCARDS_ASSIGN_SUCCESS: "Die wildcard wurde erfolgreich hinzugefügt",
    WILDCARDS_ASSIGN_ERROR_INVALID_CODE: "Code der wildcard ist nicht gültig",
    WILDCARDS_ASSIGN_ERROR: "Beim Hinzufügen der wildcard ist ein Fehler aufgetreten.",
    WILDCARDS_ASSIGN_ERROR_NOT_FOUND: "Es konnte keine wildcard mit diesem Code gefunden werden. Bitte überprüfe den Code und deine Eingabe auf eventuelle Tippfehler",
    WILDCARDS_ASSIGN_ERROR_FORBIDDEN: "Diese wildcard ist derzeit mit einem anderem tigertones-Konto verknüpft und kann aus Sicherheitsgründen nur mit der Funktion 'wildcard verknüpfen' " +
    "auf der tigerbox TOUCH gelöscht und mit einem anderen Konto verknüpft werden",

    // wildcard details page
    WILDCARDS_DETAILS_TITLE: "Informationen zur wildcard",
    WILDCARDS_DETAILS_ERROR: "Beim Laden der wildcard ist ein Fehler aufgetreten. Fehlermeldung: ",

    WILDCARDS_LABEL_CODE: "wildcard-Code",
    WILDCARDS_LABEL_PLAYING_TIME: "Spieldauer",
    WILDCARDS_LABEL_STATUS: "Status",
    WILDCARDS_LABEL_PLAYABLE_ON_ALL_BOXES: "Allen Boxen zur Verfügung stellen",
    WILDCARDS_TOGGLE_PLAYABLE_ON: "An",
    WILDCARDS_TOGGLE_PLAYABLE_OFF: "Aus",

    WILDCARDS_TRANSCODE_UPLOAD_SUCCESS: "Erfolgreich hochgeladen",
    WILDCARDS_TRANSCODE_CHECKING: "Wird geprüft",
    WILDCARDS_TRANSCODE_PROCESSING: "Wird verarbeitet",
    WILDCARDS_TRANSCODE_FAILED: "Fehler",
    WILDCARDS_TRANSCODE_FINISHED: "Bereit",
    WILDCARDS_TRANSCODE_PROCESSED: "Wird bearbeitet",
    WILDCARDS_TRANSCODE_UPLOADED: "Wird hochgeladen",

    WILDCARDS_REMOVE_BUTTON_LABEL: "wildcard entfernen",
    WILDCARDS_MODAL_REMOVE_TITLE: "Soll diese wildcard aus deinem tigertones-Konto gelöscht werden?",
    WILDCARDS_MODAL_REMOVE_TEXT: "Bist du sicher, dass diese wildcard aus deinem tigertones-Konto gelöscht werden soll?",
    WILDCARDS_MODAL_REMOVE_BUTTON_LABEL: "Entfernen",
    WILDCARDS_REMOVE_ERROR: "Beim Löschen der wildcard ist ein Fehler aufgetreten. Fehlermeldung: ",
    WILDCARDS_REMOVE_SUCCESS: "Die wildcard wurde erfolgreich gelöscht",

    WILDCARDS_MODAL_REMOVE_COVER_TITLE: "Setze das Cover der wildcard zurück",
    WILDCARDS_MODAL_REMOVE_COVER_TEXT: "Bist du sicher, dass das Cover der wildcard zurückgesetzt werden soll?",
    WILDCARDS_MODAL_REMOVE_COVER_BUTTON_LABEL: "Zurücksetzen",
    WILDCARDS_REMOVE_COVER_ERROR: "Beim Zurücksetzen des wildcard-Covers ist ein Fehler aufgetreten. Fehlermeldung: ",

    WILDCARDS_COVER_UPLOAD_FILE_ERROR: "Falsches Dateiformat. Unterstütztes Format: jpeg",

    WILDCARDS_UPLOAD_BUTTON_LABEL: "Dateien hinzufügen",
    WILDCARDS_SAVE_BUTTON_LABEL: "Speichern",
    WILDCARDS_UPLOAD_ERROR_NOT_MP3: "Die hochgeladene Datei ist nicht im MP3- oder im AAC-Format",
    WILDCARDS_UPLOAD_ERROR_MAX_LENGTH_EXCEEDED: "Die maximal erlaubte Spiellänge ist überschritten",
    WILDCARDS_UPLOAD_ERROR_FILE_SIZE: "Die Datei darf nicht größer als 500MB sein",
    WILDCARDS_UPLOAD_ERROR_PLAYLIST_SIZE: "Die Gesamtgröße der Dateien darf 500MB nicht überschreiten",
    WILDCARDS_NO_CONTENT: "Es sind noch keine Inhalte vorhanden",

    WILDCARDS_MODAL_REMOVE_ITEM_TITLE: "Möchtest du diese Audiodatei von der wildcard löschen?",
    WILDCARDS_MODAL_REMOVE_ITEM_TEXT: "Bist du sicher, dass diese Audiodatei von der wildcard gelöscht werden soll?",
    WILDCARDS_MODAL_REMOVE_ITEM_BUTTON_LABEL: "Löschen",

    WILDCARDS_SAVE_TERMS_MODAL_TITLE: "Nutzungsbedingungen",
    WILDCARDS_SAVE_TERMS_MODAL_ACCEPT: "Akzeptieren",
    WILDCARDS_SAVE_TERMS_MODAL_DECLINE: "Ablehnen",

    WILDCARDS_AGB: "<center>Ich sichere zu, über sämtliche Rechte an den von mir hochgeladenen Inhalten einschließlich des Rechts zur " +
        "Bearbeitung zu verfügen, die für eine Nutzung der wildcard-Funktionen erforderlich sind.<br /><br />" +
        "Die <a href='https://tiger.media/agb/' target='_blank' rel='noreferrer'>tigermedia AGB</a> " +
        "habe ich zur Kenntnis genommen und akzeptiert.</center>",

    WILDCARDS_MODAL_EMPTY_TITLE: "Inhalt der wildcard löschen",
    WILDCARDS_MODAL_EMPTY_TEXT: "Bist du sicher, dass der Inhalt der wildcard gelöscht werden soll?",
    WILDCARDS_MODAL_EMPTY_YES: "Ja",
    WILDCARDS_MODAL_EMPTY_SUCCESS: "Der Inhalt der wildcard wurde erfolgreich gelöscht",
    WILDCARDS_MODAL_EMPTY_ERROR: "Beim Löschen des wildcard-Inhaltes ist ein Fehler aufgetreten. Fehlermeldung: ",

    WILDCARDS_ERROR_NOT_FOUND: "Es wurde keine wildcard mit diesem Code gefunden. Bitte überprüfe, ob du den richtigen Code eingegeben hast. Überprüfe die Eingabe bitte auch auf Tippfehler.",

    //TOAST NOTIFICATIONS
    TOAST_PASSWORD_RECOVERY: "Es wurde eine E-Mail zum Zurücksetzen deines Passwortes verschickt. Bitte schaue in deinem Postfach nach.",
    TOAST_PASSWORD_CHANGE: "Dein Passwort wurde erfolgreich geändert.",
    TOAST_EMAIL_CHANGE: "Deine E-Mail-Adresse wurde geändert.",
    TOAST_PASSWORD_RESET: "Dein Passwort wurde zurückgesetzt.",
    TOAST_PASSWORD_RESET_LOG_IN: "Dein Passwort wurde geändert. Du kannst dich jetzt mit dem neuen Passwort einloggen.",
    TOAST_ERROR_FETCHING_DATA: "Beim Laden der Daten ist ein Fehler aufgetreten. Bitte versuche es später noch einmal oder wende dich an den Support. Fehlermeldung: ",

    //HUIBUH
    HUIBUH_ERROR_TITLE: "Oh, was ist denn hier passiert",
    HUIBUH_ERROR_INFO_1: "Wir konnten deine Gutschein-Informationen leider nicht laden.",
    HUIBUH_ERROR_INFO_2: "Bitte versuche es erneut, indem du deinen Zugangscode und den PIN manuell eingibst oder wende dich an " +
            "<a href='https://helpcenter.tiger.media/hc/de'>unseren Support</a>.",
    HUIBUH_ERROR_BUTTON: "Informationen zum Gutschein eingeben",
    HUIBUH_REDEMPTION_BROWSER_TAB: "Hui Buh - Das Hörspiel zum Film :: tiger.media",
    HUIBUH_REDEMPTION_TITLE: "Verspukt gut!",
    HUIBUH_REDEMPTION_INFO: "Löse hier deinen Hui Buh-Gutschein ein und erhalte Zugang zum Original-Hörspiel zum Kinofilm <b>„Hui Buh und das Hexenschloss“.</b><br/>Gib dazu Zugangscode und PIN ein, die du auf der Rückseite deines VIP Passes findest und sichere dir zusätzlich 14 Tage unbegrenzten Zugang zu über 20.000 Hörspielen, Hörbüchern und Kindermusik bei tigertones.",
    HUIBUH_REDEMPTION_CODE_PLACEHOLDER: "Bitte gib den Zugangscode ein",
    HUIBUH_REDEMPTION_PIN_PLACEHOLDER: "Bitte gib die PIN ein",
    HUIBUH_REDEMPTION_UNKNOWN_ERROR: "Ein Fehler ist aufgetreten. Bitte versuche es gleich noch einmal oder wende dich an unseren Support.",
    HUIBUH_REDEMPTION_CODE_ERROR_1: "Dieser Zugangscode ist leider ungültig.",
    HUIBUH_REDEMPTION_CODE_ERROR_ALREADY_USED: "Dieser Zugangscode wurde bereits eingelöst.",
    HUIBUH_REDEMPTION_CODE_ERROR_2: "Dieser Gutschein ist aufgrund von zu häufiger Falscheingaben für {0} Minuten gesperrt.",
    HUIBUH_REDEMPTION_CODE_ERROR_3: "PIN zu häufig falsch eingegeben. Gutschein wird für 30 Minuten gesperrt.",
    HUIBUH_REDEMPTION_CODE_ERROR_LESUNG: "Dieser Gutschein kann erst ab dem 4. November 2022 eingelöst werden.",
    HUIBUH_REDEMPTION_PIN_ERROR_1: "Diese PIN ist leider nicht korrekt. {0} Versuche verbleiben.",
    HUIBUH_REDEMPTION_HEADLINE_2: "EXKLUSIVER<br/>VIP-ZUGANG</span>",
    HUIBUH_REDEMPTION_INFO_2: "Der VIP Hörspiel-Pass ist ein Premieren-Geschenk für große und kleine Fans von Hui Buh.<br /><br />" +
            "Du erhältst Zugang zum Original-Kinofilm-Hörspiel <b>„Hui Buh und das Hexenschloss“</b> zeitlich unbegrenzt und ohne zusätzliche Kosten.",
    HUIBUH_REDEMPTION_HEADLINE_3: "SO EINFACH GEHT’S",
    HUIBUH_REDEMPTION_HEADLINE_4: "HÖREN AUCH AUF DER TIGERBOX MÖGLICH",
    HUIBUH_REDEMPTION_INFO_4: "Einfach das Hörspiel in der tigertones-App auf deine Merkliste setzen und loshören!",
    HUIBUH_REDEMPTION_HEADLINE_5: "ALLE HÖRSPIELE IN EINER APP",
    HUIBUH_REDEMPTION_INFO_5: "Mit der tigertones-App erhältst du für nur wenige Euro pro Monat Zugang zur tigertones-Audiothek für Kinder mit mehr als 20.000 Hörspielen, Hörbüchern " +
            "und Musik, inkl. aller „Hui Buh“-Hörspiele.<br /><br />" +
            "Interessiert? Dann erfahre hier mehr: <a href='http://tiger.media/pages/tigertones' target='_blank'>tiger.media/pages/tigertones</a>",

    HUIBUH_AUTHENTICATE_INFO: "Melde dich jetzt an, um deinen Gutschein einzulösen.",
    HUIBUH_AUTHENTICATION_INFO_NEW: "Deine Registrierung ist abgeschlossen. Melde dich jetzt an, um deinen Gutschein einzulösen.",
    HUIBUH_AUTHENTICATE_EMAIL: "E-Mail-Adresse:",
    HUIBUH_AUTHENTICATE_PASSWORD: "Passwort:",
    HUIBUH_AUTHENTICATE_EMAIL_HINT: "Bitte gib deine E-Mail-Adresse ein",
    HUIBUH_AUTHENTICATE_PASSWORD_HINT: "Bitte gib dein Passwort ein",
    HUIBUH_AUTHENTICATE_BUTTON: "Anmelden",
    HUIBUH_SWITCH_TO_REGISTER_INFO: "Noch kein tigertones-Konto? Dann kannst du dich jetzt hier registrieren!",
    HUIBUH_SWITCH_TO_REGISTER_BUTTON: "Jetzt registrieren",
    HUIBUH_AUTHENTICATE_EMAIL_ERROR: "Es existiert kein tigertones-Konto zu dieser E-Mail-Adresse",
    HUIBUH_AUTHENTICATE_ERROR: "Die Anmeldedaten sind nicht korrekt",

    HUIBUH_REGISTER_INFO: "Registriere dich jetzt, um das ganze tigerversum zu entdecken und deinen Gutschein einzulösen",
    HUIBUH_REGISTER_EMAIL: "E-Mail-Adresse:",
    HUIBUH_REGISTER_EMAIL_HINT: "Bitte gib deine E-Mail-Adresse ein",
    HUIBUH_REGISTER_PASSWORD: "Passwort:",
    HUIBUH_REGISTER_PASSWORD_HINT: "Mindestens 8 Zeichen",
    HUIBUH_REGISTER_PASSWORD_CONFIRMATION: "Passwort bestätigen:",
    HUIBUH_REGISTER_PASSWORD_CONFIRMATION_HINT: "Bitte bestätige dein Passwort",
    HUIBUH_REGISTER_BUTTON: "Registrieren",
    HUIBUH_SWITCH_TO_LOGIN_INFO: "Du hast bereits einen tigertones-Konto? Dann melde dich jetzt an!",
    HUIBUH_SWITCH_TO_LOGIN_BUTTON: "Jetzt anmelden",
    HUIBUH_REGISTER_INVALID_EMAIL: "Bitte gib eine gültige E-Mail-Adresse ein",
    HUIBUH_REGISTER_PASSWORD_DONT_MATCH: "Die Passwörter stimmen nicht überein",
    HUIBUH_REGISTER_PASSWORD_TOO_SHORT: "Das Passwort muss mindestens 8 Zeichen haben",
    HUIBUH_REGISTER_EMAIL_ERROR: "Es existiert bereits ein tigertones-Konto mit dieser E-Mail-Adresse",

    HUIBUH_CHANGE_ACCOUNT_BUTTON: "Neu anmelden",
    HUIBUH_REDEEM_BUTTON: "Jetzt einlösen",
    HUIBUH_PREVIEW_INFO: "Klicke auf den folgenden Button, um den Gutschein einzulösen. Anschließend erhältst du Zugang zum Hörspiel zum Film  und 14 Tage Zugang zur tigertones-Hörspieltwelt.",
    HUIBUH_SWITCH_ACCOUNT_INFO: "Du bist als <span style='font-weight: bold; color: #02abeb'>{0}</span> angemeldet. <br />Nicht dein tigertones-Konto? Dann melde dich " +
            "jetzt neu an!",

    HUIBUH_SUCCESS_TITLE: "Juhuu, das hat geklappt !<span style='color: #e92f23'>!</span>" +
            "<span style='color: #02abeb'>!</span>",
    HUIBUH_SUCCESS_INFO: "Dein Gutschein wurde erfolgreich eingelöst. Höre jetzt direkt rein!",
    HUIBUH_SUCCESS_OPEN_PLAYLIST: "Jetzt reinhören",
    HUIBUH_SUCCESS_INSTALL_APP_HINT: "Du hast die <span style='font-weight: bold'>tigertones-App</span> noch nicht installiert? Kein Problem! " +
            "Klicke einfach auf einen der folgenden Links, um tigertones herunterzuladen.",
    HUIBUH_SUCCESS_INSTALL_APP_HINT_2: "Du nutzt bereits die aktuellste Version der tigertones-App? Dann klicke auf „Jetzt reinhören“," +
            "um das Hörspiel zum Film abzurufen.",
    HUIBUH_SUCCESS_INSTALL_APP_HINT_3: "Das Hörspiel zum Film findest du in der tigertones-App unter der Rubrik „Käufe & Hörspiel-Pässe“.",


    //DDFFILM
    DDFFILM_ERROR_TITLE: "Oh, was ist denn hier passiert",
    DDFFILM_ERROR_INFO_1: "Wir konnten deine Gutschein-Informationen leider nicht laden.",
    DDFFILM_ERROR_INFO_2: "Bitte versuche es erneut, indem du deinen Zugangscode und den PIN manuell eingibst oder wende dich an " +
            "<a href='https://helpcenter.tiger.media/hc/de'>unseren Support</a>.",
    DDFFILM_ERROR_BUTTON: "Informationen zum Gutschein eingeben",
    DDFFILM_REDEMPTION_BROWSER_TAB: "Die drei ??? – Erbe des Drachen :: tiger.media",
    DDFFILM_REDEMPTION_TITLE: "Die drei ??? – Erbe des Drachen",
    DDFFILM_REDEMPTION_INFO: "Löse hier deinen Die drei ???-Gutschein ein und erhalte Zugang zum Original-Hörspiel zum Kinofilm „Die drei ??? – Erbe des Drachen“.<br/><br/>" +
            "Gib dazu Zugangscode und PIN ein, die du auf der Rückseite deines VIP Passes findest und sichere dir zusätzlich 14 Tage unbegrenzten Zugang zu über 20.000 Hörspielen, Hörbüchern und Kindermusik bei tigertones.",
    DDFFILM_REDEMPTION_CODE_PLACEHOLDER: "Bitte gib den Zugangscode ein",
    DDFFILM_REDEMPTION_PIN_PLACEHOLDER: "Bitte gib die PIN ein",
    DDFFILM_REDEMPTION_UNKNOWN_ERROR: "Ein Fehler ist aufgetreten. Bitte versuche es gleich noch einmal oder wende dich an unseren Support.",
    DDFFILM_REDEMPTION_CODE_ERROR_1: "Dieser Zugangscode ist leider ungültig.",
    DDFFILM_REDEMPTION_CODE_ERROR_TERMIN: "Dieser Gutschein kann erst ab dem 27. Januar 2023 eingelöst werden.",
    DDFFILM_REDEMPTION_CODE_ERROR_ALREADY_USED: "Dieser Zugangscode wurde bereits eingelöst.",
    DDFFILM_REDEMPTION_CODE_ERROR_2: "Dieser Gutschein ist aufgrund von zu häufiger Falscheingaben für {0} Minuten gesperrt.",
    DDFFILM_REDEMPTION_CODE_ERROR_3: "PIN zu häufig falsch eingegeben. Gutschein wird für 30 Minuten gesperrt.",
    DDFFILM_REDEMPTION_PIN_ERROR_1: "Diese PIN ist leider nicht korrekt. {0} Versuche verbleiben.",
    DDFFILM_REDEMPTION_HEADLINE_2: "EXKLUSIVER<br/>VIP-ZUGANG</span>",
    DDFFILM_REDEMPTION_INFO_2: "Der VIP Hörspiel-Pass ist ein Premieren-Geschenk für große und kleine Fans der Detektive aus Rocky Beach.<br/><br/>Du erhältst Zugang zum Original-Kinofilm-Hörspiel „Die drei ??? – Erbe des Drachen“ zeitlich unbegrenzt und ohne zusätzliche Kosten",
    DDFFILM_REDEMPTION_HEADLINE_3: "SO EINFACH GEHT’S",
    DDFFILM_REDEMPTION_HEADLINE_4: "HÖREN AUCH AUF DER TIGERBOX MÖGLICH",
    DDFFILM_REDEMPTION_INFO_4: "Einfach das Hörspiel in der tigertones-App auf deine Merkliste setzen und loshören!",
    DDFFILM_REDEMPTION_HEADLINE_5: "ALLE HÖRSPIELE IN EINER APP",
    DDFFILM_REDEMPTION_INFO_5: "Mit der tigertones-App erhältst du für nur wenige Euro pro Monat Zugang zur tigertones-Audiothek für Kinder mit mehr als 20.000 Hörspielen, Hörbüchern und Musik, inkl. aller „Die drei ???“-Hörspiele." +
            "<br /><br />" +
            "Interessiert? Dann erfahre hier mehr: <a href='https://tiger.media/pages/tigertones' target='_blank'>tiger.media/pages/tigertones</a><br/><br/>" +
            "Mehr Infos zum Film „Die drei ??? – Erbe des Drachen“ unter: <a href='https://www.diedreifragezeichenfilm.de' target='_blank'>www.DieDreiFragezeichenFilm.de</a>",
    DDFFILM_REDEMPTION_CREDIT: "©2022 CTMG. All Rights Reserved.",
    DDFFILM_AUTHENTICATE_INFO: "Melde dich jetzt an, um deinen Gutschein einzulösen.",
    DDFFILM_AUTHENTICATION_INFO_NEW: "Deine Registrierung ist abgeschlossen. Melde dich jetzt an, um deinen Gutschein einzulösen.",
    DDFFILM_AUTHENTICATE_EMAIL: "E-Mail-Adresse:",
    DDFFILM_AUTHENTICATE_PASSWORD: "Passwort:",
    DDFFILM_AUTHENTICATE_EMAIL_HINT: "Bitte gib deine E-Mail-Adresse ein",
    DDFFILM_AUTHENTICATE_PASSWORD_HINT: "Bitte gib dein Passwort ein",
    DDFFILM_AUTHENTICATE_BUTTON: "Anmelden",
    DDFFILM_SWITCH_TO_REGISTER_INFO: "Noch kein tigertones-Konto? Dann kannst du dich jetzt hier registrieren!",
    DDFFILM_SWITCH_TO_REGISTER_BUTTON: "Jetzt registrieren",
    DDFFILM_AUTHENTICATE_EMAIL_ERROR: "Es existiert kein tigertones-Konto zu dieser E-Mail-Adresse",
    DDFFILM_AUTHENTICATE_ERROR: "Die Anmeldedaten sind nicht korrekt",

    DDFFILM_REGISTER_INFO: "Registriere dich jetzt, um das ganze tigerversum zu entdecken und deinen Gutschein einzulösen",
    DDFFILM_REGISTER_EMAIL: "E-Mail-Adresse:",
    DDFFILM_REGISTER_EMAIL_HINT: "Bitte gib deine E-Mail-Adresse ein",
    DDFFILM_REGISTER_PASSWORD: "Passwort:",
    DDFFILM_REGISTER_PASSWORD_HINT: "Mindestens 8 Zeichen",
    DDFFILM_REGISTER_PASSWORD_CONFIRMATION: "Passwort bestätigen:",
    DDFFILM_REGISTER_PASSWORD_CONFIRMATION_HINT: "Bitte bestätige dein Passwort",
    DDFFILM_REGISTER_BUTTON: "Registrieren",
    DDFFILM_SWITCH_TO_LOGIN_INFO: "Du hast bereits einen tigertones-Konto? Dann melde dich jetzt an!",
    DDFFILM_SWITCH_TO_LOGIN_BUTTON: "Jetzt anmelden",
    DDFFILM_REGISTER_INVALID_EMAIL: "Bitte gib eine gültige E-Mail-Adresse ein",
    DDFFILM_REGISTER_PASSWORD_DONT_MATCH: "Die Passwörter stimmen nicht überein",
    DDFFILM_REGISTER_PASSWORD_TOO_SHORT: "Das Passwort muss mindestens 8 Zeichen haben",
    DDFFILM_REGISTER_EMAIL_ERROR: "Es existiert bereits ein tigertones-Konto mit dieser E-Mail-Adresse",

    DDFFILM_CHANGE_ACCOUNT_BUTTON: "Neu anmelden",
    DDFFILM_REDEEM_BUTTON: "Jetzt einlösen",
    DDFFILM_PREVIEW_INFO: "Klicke auf den folgenden Button, um den Gutschein einzulösen. Anschließend erhältst du Zugang zum Hörspiel zum Film.",
    DDFFILM_SWITCH_ACCOUNT_INFO: "Du bist als <span style='font-weight: bold; color: #02abeb'>{0}</span> angemeldet.<br />Nicht dein tigertones-Konto? Dann melde dich " +
            "jetzt neu an!",

    DDFFILM_SUCCESS_TITLE: "Juhuu, das hat geklappt!<span style='color: #e92f23'>!</span>" +
            "<span style='color: #02abeb'>!</span>",
    DDFFILM_SUCCESS_INFO: "Dein Gutschein wurde erfolgreich eingelöst. Höre jetzt direkt rein!",
    DDFFILM_SUCCESS_OPEN_PLAYLIST: "Jetzt reinhören",
    DDFFILM_SUCCESS_INSTALL_APP_HINT: "Du hast die <span style='font-weight: bold'>tigertones-App</span> noch nicht installiert? Kein Problem! " +
            "Klicke einfach auf einen der folgenden Links, um tigertones herunterzuladen.",
    DDFFILM_SUCCESS_INSTALL_APP_HINT_2: "Du nutzt bereits die aktuellste Version der tigertones-App? Dann klicke auf „Jetzt reinhören“," +
            "um das Hörspiel zum Film abzurufen.",
    DDFFILM_SUCCESS_INSTALL_APP_HINT_3: "Das Hörspiel zum Film findest du in der tigertones-App unter der Rubrik „Käufe & Hörspiel-Pässe“.",

    //ERROR HANDLING
    ERROR_MESSAGE_DEFAULT: "Huch, da ist leider ein Fehler aufgetreten. Bitte versuche es gleich erneut."
};

export default Strings;