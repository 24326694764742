import React, {useEffect, useState} from "react";
import './../Purchase.scss';
import {useHistory} from "react-router";
import PurchaseStatus from "../PurchaseStatus";
import Strings from "../../../../config/Strings";
import GlobalConstants from "../../../../config/GlobalConstants";
import {authCheck} from "../../../../util/authCheck";
import creditCard from "../../../../../assets/images/creditcard.png";
import debit from "../../../../../assets/images/maestro.png";
import {TigerButton} from "../../../../global/tiger-button/TigerButton";
import {Form} from "react-bootstrap";
import {ConfirmationModal, InfoModal} from "../../../../global/ConfirmationModal";
import IBAN from "iban";
import {TigerSpinner} from "../../../../global/tiger-spinner/TigerSpinner";
import googleTagManagerWrapper from "../../../../api/GoogleTagManagerWrapper";
import {GetResource} from "../../../../api/SpineClient";

export default function SubscriptionPurchasePayment() {

    const [purchaseData, setPurchaseData] = useState(JSON.parse(sessionStorage.getItem('ls.purchase')));
    const [paymentMethod, setPaymentMethod] = useState(purchaseData != null ? purchaseData.paymentMethod : null);
    const [showLoginHint, setShowLoginHint] = useState(false);
    const [debitForm, setDebitForm] = useState((purchaseData != null && purchaseData.paymentData != null) ?
        purchaseData.paymentData : {accountHolder: '', iban: '', bic: '', sepaAccepted: ''});
    const [formIsValid, setFormIsValid] = useState(false);
    const [debitFormError, setDebitFormError] = useState({accountHolder: null, iban: null, bic: null, sepaAccepted: null});
    const [loading, setLoading] = useState(false);

    const [showDebit] = useState(purchaseData && !purchaseData.selectedColor);
    const [showCC, setShowCC] = useState(true);
    const [showPP, setShowPP] = useState(true);
    const [ccc, setCcc] = useState(0);
    const [boxWidth, setBoxWidth] = useState(/*45*/ purchaseData && !purchaseData.selectedColor? 29 : 45);

    const [showMasterCardError, setShowMasterCardError] = useState(false);

    const history = useHistory();

    document.title = Strings.PURCHASE_SUBSCRIPTION_BROWSER_TAB;

    useEffect(() => {
        if (purchaseData == null) {
            history.push("/" + GlobalConstants.APP_PATH + "purchase");
        } else if (purchaseData.paymentMethod) {
            setFormIsValid(true);
        }

        if (!authCheck()) {
            //Set the max step to 2 and proceed
            purchaseData.maxStep = 1;
            sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));
            history.push("/" + GlobalConstants.APP_PATH + "purchase/subscription/authenticate");
        }

        setLoading(true);

        GetResource('api/purchases/customer/subscriptions',
            'application/json', {}, GlobalConstants.BILL_HOST).then(s => {
            if (s.error || s.result == null) {
                googleTagManagerWrapper.event('cancelPurchase', {reason: 'criticalError'});
                googleTagManagerWrapper.noPurchase();
                history.push("/" + GlobalConstants.APP_PATH + "purchase/error?code=7");
                return null;
            }
            if (s.result.active) {
                googleTagManagerWrapper.event('cancelPurchase', {reason: 'activeSubscription'});
                googleTagManagerWrapper.noPurchase();
                history.push("/" + GlobalConstants.APP_PATH + "dashboard?info=premium-active");
                return null;
            }
            setLoading(false);
        });

    }, [history, purchaseData]);

    const submitDebitChannel = (e) => {
        e.preventDefault();

        let secrets = {
            pseudocardpan: null,
            iban: null,
            bic: null,
            owner: null
        };

        purchaseData.payment = {
            channel: paymentMethod,
            channelSecrets: secrets
        };
        purchaseData.paymentData = debitForm;
        purchaseData.paymentMethod = paymentMethod;

        setPurchaseData(purchaseData);
        sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));
        googleTagManagerWrapper.paymentMethodInfo(paymentMethod);

        history.push("/" + GlobalConstants.APP_PATH + "purchase/subscription/address");
    }

    const submitPaypalChannel = (e) => {
        e.preventDefault();

        purchaseData.payment = {
            channel: paymentMethod,
        };
        purchaseData.paymentMethod = paymentMethod;

        setPurchaseData(purchaseData);
        sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));
        googleTagManagerWrapper.paymentMethodInfo(paymentMethod);

        history.push("/" + GlobalConstants.APP_PATH + "purchase/subscription/address");
    }

    const submitCreditCardChannel = (e) => {
        e.preventDefault();

        purchaseData.payment = {
            channel: paymentMethod,
        };
        purchaseData.paymentMethod = paymentMethod;

        setPurchaseData(purchaseData);
        sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));
        googleTagManagerWrapper.paymentMethodInfo(paymentMethod);

        history.push("/" + GlobalConstants.APP_PATH + "purchase/subscription/address");
    }

    const updateDebitForm = (field, value) => {
        setDebitForm({
            ...debitForm,
            [field]: value
        });
        let formIsValid = false;
        if (field === 'accountHolder') {
            formIsValid = debitForm.bic != null && debitForm.iban != null && value != null && debitForm.sepaAccepted;
        } else if (field === 'iban'){
            formIsValid = debitForm.bic != null && debitForm.accountHolder != null && value != null && debitForm.sepaAccepted;
        } else if (field === 'bic') {
            formIsValid = debitForm.accountHolder != null && debitForm.iban != null && value != null && debitForm.sepaAccepted;
        } else if (field === 'sepaAccepted') {
            formIsValid = debitForm.iban != null && debitForm.accountHolder != null && debitForm.bic != null && value;
        }
        setFormIsValid(formIsValid);
    }

    const validateDebitForm = (field, value) => {
        if (field === 'accountHolder') {
            if (value == null || value.trim().length === 0) {
                setDebitFormError({...debitFormError, accountHolder: Strings.PURCHASE_FORM_FIELD_REQUIRED});
                setFormIsValid(false);
                return;
            }
        } else if (field === 'iban') {
            if (value == null || value.trim().length === 0) {
                setDebitFormError({...debitFormError, iban: Strings.PURCHASE_FORM_FIELD_REQUIRED});
                setFormIsValid(false);
                return;
            } else if (!IBAN.isValid(value)) {
                setDebitFormError({...debitFormError, iban: Strings.PURCHASE_FORM_DEBIT_IBAN_INVALID});
                setFormIsValid(false);
                return;
            }
        } else if (field === 'sepaAccepted') {
            if (value === false) {
                setDebitFormError({...debitFormError, sepaAccepted: Strings.PURCHASE_FORM_FIELD_REQUIRED});
                setFormIsValid(false)   ;
                return;
            }
        }
        setFormIsValid(true);
    }

    const onChangeStep = (step) => {
        switch (step) {
            case 0:
                let url = "/" + GlobalConstants.APP_PATH + "bundles/colors?planVariantId=" + purchaseData.planVariantId + "&componentId=" + purchaseData.componentId + (purchaseData.discountId ? "&discountId=" + purchaseData.discountId : '');
                history.push(url);
                return;
            case 1:
                setShowLoginHint(true);
                return;
            case 2:
                //Active step -> Do nothing
                return;
            case 3:
                if (purchaseData.maxStep > 2) {
                    history.push("/" + GlobalConstants.APP_PATH + "purchase/subscription/confirmation");
                }
                if (ccc === 6) {
                    setShowCC(true);
                    setShowPP(true);
                } else {
                    setCcc(0);
                }
                return;
            case 4:
                if (purchaseData.maxStep > 3) {
                    history.push("/" + GlobalConstants.APP_PATH + "purchase/subscription/confirmation");
                }
                if (ccc === 5) {
                    setCcc(6);
                } else {
                    setCcc(0);
                }
                return;
            default:
                return;
        }
    }

    if (loading) {
        return <TigerSpinner />
    }
    return (
        <div className="purchase-container">
            <PurchaseStatus step={2} maxStep={purchaseData != null ? purchaseData.maxStep : 1} bundled={!!purchaseData.selectedColor}
                            onChangeStep={(step) => onChangeStep(step)}
            />
            <div className="purchase-info" dangerouslySetInnerHTML={{__html: Strings.PURCHASE_PAYMENT_METHOD_INFO}} onClick={() => {
                if (!ccc) {
                    setCcc(0);
                }
                if (ccc < 5) {
                    setCcc(ccc + 1);
                } else {
                    setShowCC(true);
                    setBoxWidth( 29);
                    setShowPP(true);
                }

            }}/>
            <div className="purchase-form-container">
                <div className="purchase-payment-buttons">
                    {showDebit &&
                    <div className="purchase-payment-provider" style={{width: boxWidth + '%'}}>
                        {Strings.PURCHASE_SUBSCRIPTION_PAYMENT_METHOD_DEBIT}
                        <div className={paymentMethod === GlobalConstants.PAYMENT_METHOD_DEBIT ? "purchase-payment-provider-img purchase-payment-provider-img-active" : "purchase-payment-provider-img"}
                             onClick={() => setPaymentMethod(GlobalConstants.PAYMENT_METHOD_DEBIT)}>
                            <img src={debit} alt={"payment debit"} />
                        </div>
                        <div className={"round"} style={{margin: "0 auto", width: "20px"}}>
                            <input name="debit" id="debit" type="checkbox" value={paymentMethod === GlobalConstants.PAYMENT_METHOD_DEBIT}
                                   checked={paymentMethod === GlobalConstants.PAYMENT_METHOD_DEBIT}
                                   onChange={() => setPaymentMethod(GlobalConstants.PAYMENT_METHOD_DEBIT)}
                                   />
                            <label htmlFor="debit" />
                        </div>
                    </div>
                    }
                    {showCC &&
                        <div className="purchase-payment-provider" style={{width: boxWidth + '%'}}>
                            {Strings.PURCHASE_PAYMENT_METHOD_CC}
                            <div
                                className={paymentMethod === GlobalConstants.PAYMENT_METHOD_CREDIT_CARD ? "purchase-payment-provider-img purchase-payment-provider-img-active" : "purchase-payment-provider-img"}
                                onClick={() => setPaymentMethod(GlobalConstants.PAYMENT_METHOD_CREDIT_CARD)}>
                                <img src={creditCard} alt={"payment creditcard"}/>
                            </div>
                            <div className={"round"} style={{margin: "0 auto", width: "20px"}}>
                                <input name="creditcard" id="creditcard" type="checkbox"
                                       value={paymentMethod === GlobalConstants.PAYMENT_METHOD_CREDIT_CARD}
                                       checked={paymentMethod === GlobalConstants.PAYMENT_METHOD_CREDIT_CARD}
                                       onChange={() => setPaymentMethod(GlobalConstants.PAYMENT_METHOD_CREDIT_CARD)}
                                />
                                <label htmlFor="creditcard"/>
                            </div>
                        </div>
                    }
                    {showPP &&
                            <div className="purchase-payment-provider" style={{width: boxWidth + '%'}}>
                                {Strings.PURCHASE_TICKET_PAYMENT_METHOD_PAYPAL}
                                <div
                                        className={paymentMethod === GlobalConstants.PAYMENT_METHOD_PAYPAL ? "purchase-payment-provider-img purchase-payment-provider-img-active" : "purchase-payment-provider-img"}
                                        onClick={() => setPaymentMethod(GlobalConstants.PAYMENT_METHOD_PAYPAL)}>
                                    <img src={"https://www.paypalobjects.com/webstatic/de_DE/i/de-pp-logo-150px.png"} alt={"payment paypal"}/>
                                </div>
                                <div className={"round"} style={{margin: "0 auto", width: "20px"}}>
                                    <input name="paypal" id="paypal" type="checkbox"
                                           value={paymentMethod === GlobalConstants.PAYMENT_METHOD_PAYPAL}
                                           checked={paymentMethod === GlobalConstants.PAYMENT_METHOD_PAYPAL}
                                           onChange={() => setPaymentMethod(GlobalConstants.PAYMENT_METHOD_PAYPAL)}
                                    />
                                    <label htmlFor="paypal"/>
                                </div>
                            </div>
                    }
                </div>
                <div className="purchase-form">
                    <Form className="text-left">
                        {/* The form depends on the selected payment channel */}
                        {(paymentMethod === GlobalConstants.PAYMENT_METHOD_CREDIT_CARD) &&
                            <>
                                <TigerButton className="w-100" variant="red" disabled={paymentMethod == null}
                                    type="button" onClick={(e) => submitCreditCardChannel(e)}>
                                    {Strings.PURCHASE_PROCEED_BUTTON}
                                </TigerButton>
                            </>
                        }
                        {(paymentMethod === GlobalConstants.PAYMENT_METHOD_DEBIT) &&
                            <>
                                {/* ACCOUNT OWNER*/}
                                <Form.Group className="mb-3" controlId="ticketRedemptionTicketPin">
                                    <Form.Label className="auth-label">{Strings.PURCHASE_SUBSCRIPTION_DEBIT_OWNER}</Form.Label>
                                    <Form.Control
                                        type={"text"}
                                        required
                                        value={debitForm.accountHolder}
                                        isInvalid={debitFormError.accountHolder != null}
                                        onBlur={(e) => validateDebitForm('accountHolder', e.target.value)}
                                        onChange={e => updateDebitForm('accountHolder', e.target.value)}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {debitFormError.accountHolder}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {/* IBAN */}
                                <Form.Group className="mb-3" controlId="ticketRedemptionTicketPin">
                                    <Form.Label className="auth-label">{Strings.PURCHASE_SUBSCRIPTION_DEBIT_IBAN}</Form.Label>
                                    <Form.Control
                                    type={"text"}
                                    required
                                    value={debitForm.iban}
                                    isInvalid={debitFormError.iban != null}
                                    onBlur={(e) => validateDebitForm('iban', e.target.value)}
                                    onChange={e => updateDebitForm('iban', e.target.value)}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {debitFormError.iban}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {/* BIC */}
                                <Form.Group className="mb-3" controlId="ticketRedemptionTicketPin">
                                    <Form.Label className="auth-label">{Strings.PURCHASE_SUBSCRIPTION_DEBIT_BIC}</Form.Label>
                                    <Form.Control
                                    type={"text"}
                                    required
                                    value={debitForm.bic}
                                    isInvalid={debitFormError.bic != null}
                                    onBlur={(e) => validateDebitForm('bic', e.target.value)}
                                    onChange={e => updateDebitForm('bic', e.target.value)}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {debitFormError.bic}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <div>
                                    <div className={"round"}>
                                    <input name="privacy" id="privacy" onBlur={(e) => validateDebitForm('sepaAcceped', e.target.checked)}
                                           type="checkbox" value={debitForm.sepaAccepted} onChange={(e) => updateDebitForm('sepaAccepted', e.target.checked)
                                }/>
                                    <label htmlFor="privacy"></label>
                                    </div>
                                    <label className="roundlabel purchase-cc-check-label" htmlFor="privacy">{Strings.PURCHASE_SUBSCRIPTION_DEBIT_AGB_TITLE}</label><br />
                                    <div className="purchase-form-check-info">{Strings.PURCHASE_SUBSCRIPTION_DEBIT_AGB_INFO}</div>
                                </div>
                                <br />
                                <TigerButton className="w-100" variant="red" disabled={paymentMethod == null || !formIsValid}
                                    type="button" onClick={(e) => submitDebitChannel(e)}>
                                    {Strings.PURCHASE_PROCEED_BUTTON}
                                </TigerButton>
                            </>
                        }
                        {(paymentMethod === GlobalConstants.PAYMENT_METHOD_PAYPAL) &&
                                <>
                                    <TigerButton className="w-100" variant="red" disabled={paymentMethod == null}
                                                 type="button" onClick={(e) => submitPaypalChannel(e)}>
                                        {Strings.PURCHASE_PROCEED_BUTTON}
                                    </TigerButton>
                                </>
                        }
                    </Form>
                </div>
                <ConfirmationModal
                    title={Strings.PURCHASE_REAUTHENTICATE_TITLE}
                    text={Strings.PURCHASE_REAUTHENTICATE_HINT}
                    actionButtonLabel={Strings.PURCHASE_REAUTHENTICATE_BUTTON}
                    actionButtonVariant="danger"
                    onActionPress={() => {
                        localStorage.removeItem("ls.token");
                        sessionStorage.removeItem("ls.purchase");
                        history.push("/" + GlobalConstants.APP_PATH + "purchase/" + purchaseData.planVariantId + '?selectedColor=' + (purchaseData.selectedColor ? purchaseData.selectedColor : '') + "&componentId=" + purchaseData.componentId + (purchaseData.discountId ? "&discountId=" + purchaseData.discountId : '') + (purchaseData.selectedGtin ? "&selectedGtin=" + purchaseData.selectedGtin : ''))
                    }}
                    show={showLoginHint}
                    onHide={() => setShowLoginHint(false)}
                />
            </div>

            <InfoModal
                    title={Strings.PURCHASE_PAYMENT_ERROR_TITLE}
                    text={Strings.PURCHASE_PAYMENT_ERROR_MASTERCARD}
                    show={showMasterCardError}
                    onHide={() => setShowMasterCardError(false)}/>

        </div>
    )
}